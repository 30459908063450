import { Injectable } from "@angular/core";
import { translateAndFormat } from "../i18next";
import { ValueVector } from "../models/value-vector.model";
import { Utils } from "../utils";
import { LocaleService } from "./locale/locale.service";

@Injectable({
	providedIn: "root",
})
export class BooleanTransformService {
	constructor(private localeService: LocaleService) {}

	/**
	 * @param unitsPair -- can be a undefined or a string, or an array of strings that represents
	 * the units.
	 * @param valueContent -- can be a bool or a string, or an array that represents
	 * the value in both the Imperial and Metric systems.
	 * @returns string representation of value or an index of the array of units
	 */
	public booleanTransform(
		unitsPair?: undefined | string | Array<string>,
		valueContent?: boolean | string | ValueVector,
	): string {
		if (Array.isArray(valueContent) && Array.isArray(unitsPair) && unitsPair.length > 0) {
			return `${valueContent[Utils.findUnitIndex(this.localeService, unitsPair)]}`;
		}

		if (Array.isArray(unitsPair) && unitsPair.length > 0) {
			return `${unitsPair[Utils.findUnitIndex(this.localeService, unitsPair)]}`;
		} else {
			return ["true", "false"].includes(`${valueContent}`)
				? translateAndFormat({ true: "yes", false: "no" }[`${valueContent}`], "capitalize")
				: `${valueContent}`;
		}
	}

	/**
	 * @param value can be a bool or a string, or an array that represents
	 * the value in both the Imperial and Metric systems.
	 * @param unitsPair can be a undefined or a string, or an array of strings that represents
	 * the units.
	 * @returns string representation of value.
	 */
	public booleanTransformData(value: boolean | string | ValueVector, unitsPair?: string | Array<string>) {
		return Array.isArray(value)
			? `${value[Utils.findUnitIndex(this.localeService, unitsPair)]}`
			: ["true", "false"].includes(`${value}`)
			? translateAndFormat({ true: "yes", false: "no" }[`${value}`], "capitalize")
			: `${value}`;
	}
}
