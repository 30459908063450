import { base64EncodedSeverityIcons as severityIcons } from "src/app/constants/base-64-encoded-severity-icons";

export const getSeverityStatusIconJpg = (severity: number) => {
	switch (severity) {
		case 63:
			return severityIcons.minorJpg;
		case 127:
			return severityIcons.minorJpg;
		case 255:
			return severityIcons.majorJpg;
		default:
			return severityIcons.circleCheckJpg;
	}
};
