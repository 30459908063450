import { animate, state, style, transition, trigger } from "@angular/animations";
import { SelectionModel } from "@angular/cdk/collections";
import {
	AfterContentInit,
	Component,
	ContentChildren,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	QueryList,
	SimpleChanges,
	ViewChild,
} from "@angular/core";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Subscription } from "rxjs";
import {
	MissingInspectionsAssetViewModel,
	MissingInspectionsInspectorViewModel,
} from "src/app/views/missing-inspections/missing-inspections.component";
import { resolveObjectPath } from "src/utils/resolve-object-path";
import { transformFunctions } from "./column-transforms";
import { ColumnComponent, ColumnType } from "./column/column.component";

export type SortDirection = "asc" | "desc";
export interface SortState {
	/** The id of the column being sorted. */
	active: string;
	/** The sort direction. */
	direction: SortDirection;
}

export interface Column {
	id: string;
	name: string;
	sortable: boolean;
	type: ColumnType;
	data: string;
	extraData: string;
	view: string;
	routerLinkData: unknown;
}
@Component({
	selector: "app-table",
	templateUrl: "./table.component.html",
	styleUrls: ["./table.component.scss"],
	animations: [
		// expandable rows example: https://material.angular.io/components/table/examples
		// added void: https://github.com/angular/components/issues/11990
		trigger("detailExpand", [
			state("collapsed, void", style({ height: "0px", minHeight: "0" })),
			state("expanded", style({ height: "*" })),
			transition("expanded <=> collapsed", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")),
			transition("expanded <=> void", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")),
		]),
		trigger("rotatedState", [
			state("down", style({ transform: "rotate(0)" })),
			state("up", style({ transform: "rotate(180deg)" })),
			transition("down <=> up", animate("225ms cubic-bezier(0.4,0.0,0.2,1)")),
		]),
	],
})
export class TableComponent implements AfterContentInit, OnDestroy, OnInit, OnChanges {
	@ContentChildren(ColumnComponent) columns!: QueryList<ColumnComponent>;
	@ViewChild(MatSort, { static: true }) sort: MatSort;
	@Input() sortActive: string;
	@Input() sortDirection: "asc" | "desc";
	@Input() dataSource: MatTableDataSource<unknown>;
	@Input() view: string;
	@Output() sortChange = new EventEmitter<SortState>();
	@Output() selectionModelChange = new EventEmitter<SelectionModel<any>>();
	@Output() sortObjChange = new EventEmitter<MatSort>();

	tableColumns: Array<Column> = [];
	rotateState: string[] = [];
	displayedColumns: Array<string> = [];
	extraDataCol: Column;
	buttonsCol: Column;
	selectionModel = new SelectionModel(true, []);
	subscriptions = new Subscription();
	name: string[] = [];
	type: string[] = [];
	routerLink: string[] = [];

	ngOnInit(): void {
		this.selectionModelChange.emit(this.selectionModel);

		this.sortObjChange.emit(this.sort);
	}
	ngOnChanges(_: SimpleChanges): void {
		this.updateView();
	}
	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	resolvePath(path: string, obj: unknown) {
		return resolveObjectPath(path.split("."), obj);
	}

	updateView() {
		if (this.columns) {
			this.extraDataCol = this.columns.filter(
				column => column.extraData && (column.view === "*" || column.view === this.view),
			)[0];

			this.buttonsCol = this.columns.filter(column => column.type === "button")[0];

			this.tableColumns = this.columns
				.filter(column => !column.extraData && (column.view === "*" || column.view === this.view))
				.map(col => {
					return {
						id: col.id,
						name: col.name,
						sortable: col.sortable,
						type: col.type,
						data: col.data,
						extraData: col.extraData,
						view: col.view,
						routerLinkData: col.routerLinkData ? JSON.parse(col.routerLinkData) : null,
					};
				});

			this.displayedColumns = [
				"select",
				...this.tableColumns.filter(column => column.type !== "button").map(column => column.id),
				this.tableColumns.filter(column => column.type === "button").length > 0 ? "button" : "chevron",
			];
		}
	}

	ngAfterContentInit(): void {
		setTimeout(() => {
			this.updateView();

			// update whenever the datasource changes
			this.subscriptions.add(
				this.dataSource.connect().subscribe(() => {
					this.resetAnimations();
				}),
			);
		});
	}

	getDataSource() {
		return this.dataSource;
	}
	getDividerClass(row: unknown, i: number) {
		if (this.selectionModel?.isSelected(row) && this.rotateState[i] === "down") {
			return "highlight";
		} else if (this.selectionModel?.isSelected(row) && this.rotateState[i] === "up") {
			return "white-divider-highlight";
		} else {
			return null;
		}
	}
	expansionAnimation(_element: unknown, index: number) {
		return this.rotateState[index] === "up" ? "expanded" : "collapsed";
	}
	rotate(i: number) {
		this.rotateState = this.rotateState.map((rotation: string, k: number) => {
			if (k === i) {
				return rotation === "down" ? "up" : "down";
			} else {
				return rotation;
			}
		});
	}

	resetAnimations() {
		this.rotateState = new Array(this.dataSource.data?.length ?? 0).fill("down");
		this.selectionModel.clear();
		this.name = [];
		this.type = [];
		this.routerLink = [];
	}

	/**
	 *
	 * @param event Mouse event
	 * @param row row data
	 * @returns Toggle the checkbox selection for the row or returns null if it didn't received the event
	 */
	handleCheckboxChange(
		event: MouseEvent,
		row: MissingInspectionsInspectorViewModel | MissingInspectionsAssetViewModel,
	) {
		return event ? this.selectionModel.toggle(row) : null;
	}

	/**
	 * Stop event propagation on checkbox click
	 * @param event Mouse event
	 */
	handleCheckboxClick(event: MouseEvent) {
		event.stopPropagation();
	}

	/**
	 * Label for the table checkbox
	 * @param row possible row selected on the table
	 * @returns Aria label for the row
	 */
	checkboxLabel(row: unknown | undefined) {
		return !row
			? `${this.isAllSelected() ? "deselect" : "select"} all` // Master checkbox selected
			: `${this.selectionModel.isSelected(row) ? "deselect" : "select"} row`; // Individual row selected
	}

	/**
	 * Checks if all rows are selected
	 * @returns True if all rows are selected
	 */
	isAllSelected() {
		const numSelected = this.selectionModel.selected?.length ?? 0;
		const numRows = this.getDataSource().data?.length ?? 0;
		return numSelected === numRows;
	}

	/**
	 * Toggles between all and none row selected
	 */
	masterToggle() {
		this.isAllSelected()
			? this.selectionModel.clear()
			: this.getDataSource().data.forEach(row => this.selectionModel.select(row));
	}

	getTransformFn(transformFnKey: string) {
		return transformFunctions[transformFnKey];
	}
	/**
	 * Navigate to new tab on the browser by url
	 * @param data router link data
	 * @param row row data
	 */
	navigateToRouterLink(data: any, row: unknown | undefined) {
		const routerLinkData = JSON.parse(data);
		// allow "additional2" to reference a property in the row data
		let additional2 = routerLinkData.additional2 ?? "";
		if (additional2[0] === "@") {
			additional2 = this.resolvePath(additional2.slice(1), row);
		}

		// has a transform function been specified?
		const transformFnKey = routerLinkData.transformFn;
		if (transformFnKey) {
			const transformFn = this.getTransformFn(transformFnKey);

			if (!transformFn) {
				throw new Error(`no transform function for key "${transformFnKey}"`);
			}

			additional2 = transformFn(additional2);
		}

		const url =
			routerLinkData.path + this.resolvePath(routerLinkData.idExp, row) + routerLinkData.additional + additional2;
		window.open(url);
	}
}
