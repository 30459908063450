export function formatIso(isoString: string) {
	// Regular expression to match the date and time parts of the ISO string
	// Captures YYYY-MM-DD and HH:MM:SS(.SSS)Z, where milliseconds (.SSS) are optional
	const regex = /^(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2}:\d{2}(?:\.\d{3})?Z)/;

	// Replace the matched groups with the desired format, keeping the Z and including milliseconds
	const formattedString = isoString.replace(regex, "$1 ($2)");

	return formattedString;
}
