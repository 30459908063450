import equal from "fast-deep-equal";

export function arraysEquivalent(arr1: unknown[], arr2: unknown[]): boolean {
	let equivalent = true;

	if (arr1.length === arr2.length) {
		// entry-by-entry compare
		arr1.forEach((obj1, i) => {
			if (!equal(obj1, arr2[i])) {
				equivalent = false;
			}
		});
	} else {
		equivalent = false;
	}

	return equivalent;
}
