import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Geolocation } from "../../../models/evir-api.models";
import { InspectionGet, Id } from "../../../models/openAPIAliases";
import { RequestService } from "src/app/services/request/request.service";

@Injectable({
	providedIn: "root",
})
export class InspectionService {
	constructor(public requestService: RequestService) {}

	public getInspection(inspectionId: string, companyId: Id, endpoint: string): Observable<InspectionGet> {
		return this.requestService.get<InspectionGet>({
			url: `${endpoint}/inspections/${inspectionId}?companyId=${companyId}`,
		});
	}

	public getGeolocation(lat: string, long: string, endpoint: string): Observable<Geolocation> {
		return this.requestService.get<Geolocation>({
			url: `${endpoint}/reverse?latitude=${lat}&longitude=${long}`,
		});
	}
}
