import { timeDifference } from "./timeDifference";

/**
 *  Get the lapse between two dates
 * @param startTime Start date
 * @param endTime End date
 * @returns The time lapse in HH:MM:SS between two dates.
 */
export const timeLapse = (startTime: Date, endTime: Date) => {
	const timeDiff = timeDifference(endTime)(startTime);

	// Converting days into hours
	const hours = timeDiff.hours + timeDiff.days * 24;

	// appending zeroes to display in the format of HH:MM:SS format (example 00: 09: 01)
	const formattedHours = hours.toString().padStart(2, "0");
	const formattedMinutes = timeDiff.minutes.toString().padStart(2, "0");
	const formattedSeconds = timeDiff.seconds.toString().padStart(2, "0");

	return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};
