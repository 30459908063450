import {
	AfterViewInit,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	QueryList,
	SimpleChanges,
	ViewChildren,
	ViewEncapsulation,
} from "@angular/core";
import { MatCheckbox, MatCheckboxChange } from "@angular/material/checkbox";
import { Subscription } from "rxjs";
import { translateAndFormat } from "src/app/i18next";
import { MemCacheService } from "src/app/services/mem-cache/mem-cache.service";

export interface Option {
	label: string;
	value: string;
	icon?: string;
}

export interface CheckboxOption extends Option {
	checkboxElement?: MatCheckbox;
}
export interface DropdownMultiSelectState {
	dropdownMultiSelectLabel: string;
	potentialDropdownMultiSelectLabel: string;
	buttonIconValue: string;
	optionsSelected: Array<CheckboxOption>;
	showLabel: boolean;
}

@Component({
	selector: "app-multi-select-dropdown",
	templateUrl: "./multi-select-dropdown.component.html",
	styleUrls: ["./multi-select-dropdown.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class MultiSelectDropdownComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
	/**
	 * Dropdown context, "filter type: view".
	 * For example: asset-type-filter inspection-history.
	 */
	@Input() context: string;
	/**
	 * Dropdown label
	 */
	@Input() dropdownLabel: string;
	/**
	 * Dropdown Multi Select options
	 */
	@Input() options: ReadonlyArray<Option> = [];
	/**
	 * Output - Submit options selected
	 */
	@Output() submitDropdownMultiSelect = new EventEmitter<Array<string>>();
	/**
	 * View Children - Mat Checkbox
	 */
	@ViewChildren(MatCheckbox) checkboxes: QueryList<MatCheckbox>;

	buttonIconValue = "arrow_drop_down";
	showMenu = false;
	defaultCheckState: Record<string, boolean> = {};
	defaultState: DropdownMultiSelectState = {
		dropdownMultiSelectLabel: "",
		potentialDropdownMultiSelectLabel: "",
		buttonIconValue: "arrow_drop_down",
		optionsSelected: [],
		showLabel: false,
	};
	filterButtonClass = "filter-button-not-focused";
	filterButtonLabelClass = "filter-button-label-not-focused";
	filterButtonTextClass = "filter-button-text-value-not-selected";
	potentialFilterButtonText = "";
	potentiallySelectedOptions: Option[] = [];
	selectedOption = "";
	selectedOptions: CheckboxOption[] = [];
	showLabel = false;
	state: DropdownMultiSelectState;
	stateKey: string;
	subscriptions = new Subscription();
	sortedOptions: Array<Option>;

	constructor(public memCacheService: MemCacheService) {}
	ngOnChanges(changes: SimpleChanges): void {
		if (changes.options) {
			this.updateSortedOptions();
		}
	}

	/**
	 * On Init
	 * Subscribe to mem cache service to get the state of the dropdownå
	 */
	ngOnInit() {
		this.defaultState.dropdownMultiSelectLabel = this.dropdownLabel;

		this.subscriptions.add(
			this.memCacheService.cleared$().subscribe(() => {
				this.memCacheService.setValue(this.stateKey, null);

				this.captureSelection();

				this.submitDropdownMultiSelect.emit([]);
			}),
		);

		// sort the options
		this.updateSortedOptions();
	}

	updateSortedOptions() {
		this.sortedOptions = [...this.options].sort(({ label: labelA }, { label: labelB }) =>
			labelA.toLocaleLowerCase().localeCompare(labelB.toLocaleLowerCase()),
		);
	}
	/**
	 * After view Init
	 * Defer captureSelection until after the view detection cycle has completed
	 */
	ngAfterViewInit() {
		setTimeout(() => {
			this.captureSelection();
		});
	}

	/**
	 * On Destroy
	 * Unsubscribe from all subscriptions
	 */
	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	/**
	 * Initialize the component state
	 */
	initState() {
		this.stateKey = this.context;

		this.state = this.getState();

		if (!this.state) {
			this.state = {
				...this.defaultState,
			};
		}

		this.state.optionsSelected.forEach(option => {
			option.checkboxElement = this.checkboxes.find(checkbox => checkbox.id === option.value);
		});

		this.showLabel = this.state.showLabel;
	}

	/**
	 * Update the dropdown multi select state every time the checkbox changes state
	 */
	matCheckboxChange(event: MatCheckboxChange, option: Option) {
		if (event.checked) {
			if (!this.optionIsInSelectedOptions(option)) {
				const typedOption: CheckboxOption = {
					...option,
					checkboxElement: event.source,
				};

				this.potentiallySelectedOptions.push(typedOption);
			}
		} else {
			this.removeOptionFromSelectedOptions(option);
		}
	}

	/**
	 * Stop Event propagation to prevent the menu from collapsing on checkbox click
	 * @param event Mouse Event
	 */
	matCheckboxClick(event: MouseEvent) {
		event.stopPropagation();
	}

	/**
	 * Submit the dropdown selected values and update the state
	 */
	dropdownSubmit() {
		this.memCacheService.setValue("resetState", true);
		const submitEvent = this.potentiallySelectedOptions.map(option => option.value);

		this.submitDropdownMultiSelect.emit(submitEvent);

		if (this.potentiallySelectedOptions.length === 0) {
			this.potentialFilterButtonText = this.dropdownLabel;
			this.showLabel = false;
			this.filterButtonTextClass = "filter-button-text-value-not-selected";
		} else if (this.potentiallySelectedOptions.length === 1) {
			this.potentialFilterButtonText = this.potentiallySelectedOptions[0].label;
			this.showLabel = true;
			this.filterButtonTextClass = "filter-button-text-value-selected";
		} else {
			this.potentialFilterButtonText = translateAndFormat("multiple", "capitalize");
			this.showLabel = true;
			this.filterButtonTextClass = "filter-button-text-value-selected";
		}

		this.selectedOption = this.potentialFilterButtonText;
		this.selectedOptions = [...this.potentiallySelectedOptions];

		this.flushState();

		this.showMenu = false;
	}

	/**
	 * Handle menu open
	 * Reset all the checkboxes, internally tracked state, and  check all the currently selected options.
	 */
	handleMenuOpen() {
		this.showMenu = true;
		this.buttonIconValue = "arrow_drop_up";
		this.filterButtonClass = "filter-button-focused";
		this.filterButtonLabelClass = "filter-button-label-focused";

		this.defaultCheckState = {};

		this.selectedOptions.forEach(option => {
			this.defaultCheckState[option.label] = true;
		});

		this.potentiallySelectedOptions = [...this.selectedOptions];
	}

	/**
	 * Handle menu close
	 */
	handleMenuClose() {
		this.buttonIconValue = "arrow_drop_down";
		this.filterButtonClass = "filter-button-not-focused";
		this.filterButtonLabelClass = "filter-button-label-not-focused";
		this.showMenu = false;
	}

	/**
	 * Update cashed dropdown state
	 */
	flushState() {
		this.memCacheService.setValue<DropdownMultiSelectState>(this.stateKey, {
			dropdownMultiSelectLabel: this.selectedOption,
			potentialDropdownMultiSelectLabel: this.potentialFilterButtonText,
			buttonIconValue: this.buttonIconValue,
			optionsSelected: [...this.selectedOptions],
			showLabel: this.showLabel,
		});
	}

	/**
	 * Checks if an option was already selected
	 * @param option Option object
	 * @returns True or False depending on whether the option is in the selected options array
	 */
	private optionIsInSelectedOptions(option: Option) {
		return this.potentiallySelectedOptions.find(potentialOption => potentialOption.value === option.value)
			? true
			: false;
	}

	/**
	 * Remove a given option from the potentiallySelectedOptions array
	 * @param option Option object
	 */
	private removeOptionFromSelectedOptions(option: Option) {
		this.potentiallySelectedOptions = this.potentiallySelectedOptions.filter(
			potentialOption => potentialOption.value !== option.value,
		);
	}

	/**
	 * Get current dropdown multi select state from cache
	 * @returns DropdownMultiSelectState object
	 */
	private getState() {
		return this.memCacheService.getValue<DropdownMultiSelectState>(this.stateKey);
	}

	/**
	 * Capture current dropdown multi select state
	 */
	private captureSelection() {
		this.initState();

		this.filterButtonTextClass =
			this.state.optionsSelected.length > 0
				? "filter-button-text-value-selected"
				: "filter-button-text-value-not-selected";

		this.potentiallySelectedOptions = [...this.state.optionsSelected];
		this.selectedOptions = [...this.state.optionsSelected];

		this.selectedOption = this.state.dropdownMultiSelectLabel;
	}

	isInspectionType() {
		return this.context && this.context.includes("inspectionType");
	}
}
