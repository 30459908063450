import { Component, Inject, OnInit, ViewContainerRef, ViewEncapsulation } from "@angular/core";
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Select, Store } from "@ngxs/store";
import { PermissionsService } from "@zonar-ui/auth";
import { IUser } from "@zonar-ui/auth/lib/models/user.model";
import { Observable, combineLatest } from "rxjs";
import { filter, map, startWith } from "rxjs/operators";
import { ErrorResponse } from "src/app/models/error-response";
import { components } from "src/app/models/openAPI";
import { GlobalApiCallsService } from "src/app/services/global-api-calls.service";
import { LocaleService } from "src/app/services/locale/locale.service";
import { environment } from "src/environments/environment";
import { getCompanyContext } from "src/utils/getCompanyContext";
import { isDefined } from "src/utils/isDefined";
import { newDate } from "src/utils/newDate";
import { AppState } from "../../../app.state";
import { Application, Role } from "../../../models/core-api.models";
import { RepairFormData, RepairModalData } from "../../../models/modal-data.models";
import { OpenDefectTableViewModel } from "../../../models/open-defect-table.models";
import {
	RepairRequest,
	RepairResolutionsResponseInner,
	RepairResponse,
	RepairStatusesEnum,
} from "../../../models/openAPIAliases";
import { InputRepairObject } from "../repair-buttons/repair-buttons.component";
import { RepairService } from "../service/repair.service";
import {
	GetCompanyResolutions,
	GetMechanics,
	GetSupervisingMechanics,
	PostRepair,
	RepairState,
	ResetRepairs,
	SetAllMechanics,
} from "../state/repairs.state";

// form control needs an object that returns boolean in order to tell if input valid or not
export interface IsMechanic {
	isMechanic: boolean;
}

export interface CommentRequired {
	commentRequired: boolean;
}

// this validator function is for entire form cross-formcontrol validation, e.g. to make sure user input comment if they selected other in the resolution field
// see https://angular.io/api/forms/ValidatorFn
// see https://angular.io/guide/form-validation#cross-field-validation
export const commentIsRequired: ValidatorFn = (control: UntypedFormGroup): CommentRequired => {
	const resolution = control.get("resolution");
	const comment = control.get("comments");

	return resolution.value &&
		resolution.value.resolutionKey === "other" &&
		(comment.value === null || comment.value === "")
		? { commentRequired: true }
		: null;
};

@Component({
	selector: "app-repair-modal",
	templateUrl: "./repair-modal.component.html",
	styleUrls: ["./repair-modal.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class RepairModalComponent implements OnInit {
	@Select(AppState.getSelectedCompanyId) selectedCompanyId$: Observable<string>;
	@Select(AppState.selectApplication) application$: Observable<Application>;
	@Select(AppState.selectUser) user$: Observable<IUser>;
	@Select(RepairState.getAllMechanics) allMechanics$: Observable<Array<IUser>>;
	@Select(RepairState.getCompanyResolutions) companyResolutions$: Observable<RepairResolutionsResponseInner[]>;
	@Select(RepairState.getMechanics) mechanics$: Observable<Array<IUser>>;
	@Select(RepairState.getNumberOfDefects) numberOfDefects$: Observable<number>;
	@Select(RepairState.getRepair) repairs$: Observable<RepairResponse[]>;
	@Select(RepairState.getSupervisingMechanics) supervisingMechanics$: Observable<Array<IUser>>;

	public allMechanics: Array<IUser> = [];
	public commentCharacterCounter = 0;
	public companyId = "";
	public errors: string[] = [];
	public filteredMechanics: Observable<Array<IUser>>;
	public fullMechanicName: string;
	public isPostingRepairs = false;
	public locale = this.localeService.getCurrentLocale();
	public mechanicStringRegex: RegExp;
	public numberOfDefects = 0;
	public otherSelected = false;
	public repairForm: UntypedFormGroup;
	public repairObject: RepairRequest;
	public repairStatus: RepairStatusesEnum = "pending";
	public searchControl: UntypedFormControl = new UntypedFormControl(null, [
		this.mustMatchMechanic, // dropdown validator
	]);
	public searchMechanicString: string;
	public showMechanicSelect = false;
	public userFirstName = "";
	public userEmail = "";
	public userId = "";
	public userLastName = "";
	public workOrderCharacterCounter = 0;

	constructor(
		private store: Store,
		private permissionsService: PermissionsService,
		public dialogRef: MatDialogRef<RepairModalComponent>,
		public viewContainerRef: ViewContainerRef,
		public localeService: LocaleService,
		public repairService: RepairService,
		public globalApiCallsService: GlobalApiCallsService,
		@Inject(MAT_DIALOG_DATA) public repair: InputRepairObject,
	) {
		// so user cannot accidentally close modal by clicking outside it
		dialogRef.disableClose = true;
	}

	ngOnInit() {
		this.numberOfDefects$
			.pipe(filter(isDefined))
			.subscribe((numberOfDefects: number) => (this.numberOfDefects = numberOfDefects));

		// need the username to inject into form
		this.user$.pipe(filter(isDefined)).subscribe((user: IUser) => {
			this.userId = user.id;
			this.userFirstName = user.firstName;
			this.userLastName = user.lastName;
			this.userEmail = user.email;
		});

		// get our env vars and company id to dispatch for the company's default or custom resolutions
		// todo: use existing utility function
		combineLatest([this.selectedCompanyId$, this.application$])
			.pipe(
				filter(
					([selectedCompanyId, application]) => isDefined(selectedCompanyId) && isDefined(application?.roles),
				),
			)
			.subscribe(([selectedCompanyId, application]) => {
				this.companyId = selectedCompanyId;
				this.store.dispatch(
					new GetCompanyResolutions(this.companyId, environment.environmentConstants.APP_ENDPOINT_EVIR),
				);

				// if user is supervisor, find IDs of mechanic and supervisor and dispatch for mechanic and supervisor users
				if (application && this.repair.canAssignMechanic) {
					const mechanicRole = application.roles.find(
						(role: Role) => role.id === environment.environmentConstants.APP_ROLE_REPAIR_DEFECT_MECHANIC,
					);
					const supervisingMechanicRole = application.roles.find(
						(role: Role) => role.id === environment.environmentConstants.APP_ROLE_ASSIGN_MECHANIC,
					);

					if (mechanicRole && supervisingMechanicRole) {
						this.setMechanicsAndSupervisingMechanics(mechanicRole, supervisingMechanicRole);
					}
				}
			});

		// If user is supervising mechanic, get mechanics and supervising mechanics, sort them by last name and use them for searchControl
		if (this.repair.canAssignMechanic) {
			getCompanyContext(this.permissionsService.getCurrentCompanyContext()).subscribe(currentCompanyContext => {
				currentCompanyContext.loginMode === "GROUP_POLICY"
					? this.allMechanics$.subscribe((allMechanics: Array<IUser>) => {
							this.showMechanicSelect = true;
							if (allMechanics) {
								this.allMechanics = this.sortMechanics(allMechanics);
								this.filteredMechanics = this.getControlInput();
							} else {
								this.showMechanicSelect = false;
							}
					  })
					: combineLatest([this.mechanics$, this.supervisingMechanics$]).subscribe(
							([mechanics, supervisingMechanics]) => {
								let everyMechanic: Array<IUser> = [];
								// allow user to see mechanic select
								this.showMechanicSelect = true;

								// if api call returns both mechanics and supervising mechanics, combine
								if (mechanics && supervisingMechanics) {
									everyMechanic = [...mechanics, ...supervisingMechanics];
									// if no supervising mechanic users
								} else if (mechanics) {
									everyMechanic = mechanics;

									// if no mechanic users
								} else if (supervisingMechanics) {
									everyMechanic = supervisingMechanics;

									// on the off-chance the current user is the only mechanic, turn off mechanic select visibility
								} else {
									this.showMechanicSelect = false;
								}
								this.allMechanics = this.sortMechanics(everyMechanic);

								this.filteredMechanics = this.getControlInput();
							},
					  );
			});
		}

		// modal dialog content changes based on selected repair status
		this.repairForm =
			this.repair.type === "Repaired" ? this.createRepairedFormGroup() : this.createOtherFormGroup();

		// set repair status 1-3
		this.repairStatus = this.getRepairStatus(this.repair.type);

		// get the amount of characters currently in workOrder and send to characterCounter
		if (this.repairForm.get("workOrder")) {
			this.repairForm.get("workOrder").valueChanges.subscribe((value: string) => {
				this.workOrderCharacterCounter = value.length;
			});
		}

		// get the amount of characters currently in comments and send to characterCounter
		this.repairForm.get("comments").valueChanges.subscribe((value: string) => {
			this.commentCharacterCounter = value.length;
		});

		// if user selected repaired modal, capture user's resolution selection
		// otherSelected bool controls the visibility of the red required asterisk in template
		if (this.repairForm.controls.resolution) {
			this.repairForm.controls.resolution.valueChanges.subscribe((value: RepairResolutionsResponseInner) => {
				this.otherSelected = value.resolutionKey === "other" ? true : false;
			});
		}
	}

	public get repaired() {
		return this.repair.type === "Repaired";
	}

	public get pending() {
		return this.repair.type === "Pending";
	}

	public get repairNotNeeded() {
		return this.repair.type === "Repair not needed";
	}

	public filterMechanics(searchedMechanic: IUser | string): Array<IUser> {
		const filteredAllMechanics: Array<IUser> = [];
		const userIds: string[] = [];
		// get the string user input and create regex e.g. b => .*b.* | bo bob => .*bo\sbob.*
		this.searchMechanicString = typeof searchedMechanic !== "string" ? "" : searchedMechanic.toLowerCase();
		this.mechanicStringRegex = new RegExp(`.*${this.searchMechanicString}.*`, "g");

		// combine first and last names in order to search full name.
		// a name of Bobo Bobson and search of .*bo\sbob.* returns full mechanic object of Bo

		//TO_DO : This is a temporary fix and design might be updated in future to display all the profiles effectively.
		//combine the firstname and lastname to form full mechanic name and then move the users having unique names
		this.allMechanics.forEach((filterUserMechanics: IUser) => {
			if (!userIds.includes(filterUserMechanics.id)) {
				userIds.push(filterUserMechanics.id);
				filteredAllMechanics.push(filterUserMechanics);
			}
		});
		return filteredAllMechanics.filter((mechanic: IUser) => {
			this.fullMechanicName = `${mechanic.firstName} ${mechanic.lastName}`;
			return this.fullMechanicName.toLowerCase().match(this.mechanicStringRegex);
		});
	}

	// display full mechanic name in input box when full name selected from autocomplete
	public displayName(mechanic: IUser): string {
		return mechanic ? `${mechanic.lastName}, ${mechanic.firstName}` : "";
	}

	// close dialog and don't do anything if user presses cancel or clear
	public cancelRepair(): void {
		this.dialogRef.close();
	}

	// for each defect that was passed into modal, post the repair and close dialog
	public submitRepair(): void {
		let configId: string;
		this.errors = [];

		this.repair.defects.forEach((defect: OpenDefectTableViewModel) => {
			this.postRepairs(defect, this.repairForm.value, this.searchControl.value);
			configId = defect.configId;
		});

		const repairModalData: RepairModalData = {
			configId: configId,
			mechanic: this.searchControl.value,
			repairForm: this.repairForm.value,
			timestamp: newDate().toISOString(),
		};

		this.repairs$.subscribe((repairs: (RepairResponse | ErrorResponse)[]) => {
			if (repairs) {
				// check for the presense of errors
				repairs.forEach(repair => {
					if (repair instanceof ErrorResponse) {
						this.errors = [
							...this.errors,
							`${typeof repair.message === "object" ? JSON.stringify(repair.message) : repair.message}`,
						];
					}
				});
				if (this.errors.length === 0) {
					// wait for all defects to be repaired before resetting repairs and closing the modal
					if (repairs.length === this.numberOfDefects) {
						this.store.dispatch(new ResetRepairs());
						this.dialogRef.close(repairModalData);
					}
				} else {
					this.isPostingRepairs = false;
				}
			}
		});
	}

	// Post repair to API
	public postRepairs(defect: OpenDefectTableViewModel, form: RepairFormData, mechanic: IUser): void {
		this.repairObject = {
			repairStatus: this.repairStatus,
			userId: this.userId,
			userFirstName: this.userFirstName,
			userLastName: this.userLastName,
			companyId: this.companyId,
			assetId: defect.assetId,
			mechanicId: this.userId,
			mechanicFirstName: this.userFirstName,
			mechanicLastName: this.userLastName,
			mechanicEmail: mechanic ? mechanic.email : this.userEmail,
		};

		// if a mechanic was selected in the searchbox change from user info
		if (mechanic) {
			this.repairObject.mechanicId = mechanic.id;
			this.repairObject.mechanicFirstName = mechanic.firstName;
			this.repairObject.mechanicLastName = mechanic.lastName;
		}

		// if it was a repair and there's a resolution radio checked (there should always be if it's a repair)
		if (form.resolution) {
			this.repairObject.resolutionType = form.resolution.resolutionKey;
			this.repairObject.languageChoice =
				(this.localeService.getCurrentLocale() as components["schemas"]["languageChoiceEnum"]) || "en-us";

			// default to value for 'en-us'
			this.repairObject.resolution =
				form.resolution.resolutionValues[this.repairObject.languageChoice] ||
				form.resolution.resolutionValues["en-us"];
		}

		if (form.workOrder) {
			this.repairObject.workOrder = form.workOrder;
		}

		if (form.comments) {
			this.repairObject.comment = form.comments;
		}

		this.store.dispatch(
			new PostRepair(this.repairObject, defect.defectId, environment.environmentConstants.APP_ENDPOINT_EVIR),
		);
	}

	public getRepairStatus(type: string): RepairStatusesEnum {
		switch (type) {
			case "Pending":
				return "pending";
			case "Repair not needed":
				return "ignored";
			case "Repaired":
				return "repaired";
			default:
				return "pending";
		}
	}

	// repairs must have resolution, checkbox, and comment if other selected in resolution formcontrol
	public createRepairedFormGroup(): UntypedFormGroup {
		return new UntypedFormGroup(
			{
				resolution: new UntypedFormControl(null, [Validators.required]),
				workOrder: new UntypedFormControl(null, [Validators.maxLength(128)]),
				comments: new UntypedFormControl(null, [Validators.maxLength(512)]),
				checkbox: new UntypedFormControl(false, [Validators.requiredTrue]),
			},
			{ validators: commentIsRequired },
		);
	}

	// covers the pending and ignored statuses which must have comments and checkbox
	public createOtherFormGroup(): UntypedFormGroup {
		return new UntypedFormGroup({
			comments: new UntypedFormControl(null, [Validators.required, Validators.maxLength(512)]),
			checkbox: new UntypedFormControl(false, [Validators.requiredTrue]),
		});
	}

	// user must either select a mechanic from dropdown or select nothing at all
	public mustMatchMechanic(control: AbstractControl): IsMechanic {
		const selection: IUser | string = control.value;
		return typeof selection === "string" && selection !== "" ? { isMechanic: false } : null;
	}

	// map user in hydratedUserProfile to new array, filter out current user, sort based on user's last name a:z
	public sortMechanics(allMechanics: Array<IUser>): Array<IUser> {
		return allMechanics
			.filter(user => user.firstName !== "Zonar")
			.sort((a, b) => (a.lastName > b.lastName ? 1 : -1));
	}

	// as searchControl changes, filter current list of mechanics in dropdown
	public getControlInput(): Observable<Array<IUser>> {
		return this.searchControl.valueChanges.pipe(
			startWith(null),
			map((mechanic: IUser | string) => this.filterMechanics(mechanic)),
		);
	}

	public setMechanicsAndSupervisingMechanics(mechanicRole: Role, supervisingMechanicRole: Role) {
		getCompanyContext(this.permissionsService.getCurrentCompanyContext()).subscribe(currentCompanyContext => {
			currentCompanyContext.loginMode === "GROUP_POLICY"
				? this.getMechanicsFromPolicies(mechanicRole.id, supervisingMechanicRole.id)
				: this.getMechanicsFromProfiles(mechanicRole.id, supervisingMechanicRole.id);
		});
	}

	private getMechanicsFromProfiles(mechanicRoleId: string, supervisingMechanicRoleId: string) {
		this.store.dispatch([
			new GetMechanics(this.companyId, mechanicRoleId, environment.environmentConstants.APP_ENDPOINT_CORE),
			new GetSupervisingMechanics(
				this.companyId,
				supervisingMechanicRoleId,
				environment.environmentConstants.APP_ENDPOINT_CORE,
			),
		]);
	}

	private getMechanicsFromPolicies(mechanicRoleId: string, supervisingMechanicRoleId: string) {
		// Call the /policies endpoint with the companyId
		this.globalApiCallsService
			.getPoliciesFromCompany(this.companyId, environment.environmentConstants.APP_ENDPOINT_CORE)
			.subscribe(policies => {
				// Filter the results down to policies containing mechanic and supervising mechanic roles
				// TODO: GP - Remove this when the API supports filtering
				const policiesWithRole = policies.filter(policy =>
					policy.grants.filter(grant =>
						grant.roles.some(role => mechanicRoleId === role.id || supervisingMechanicRoleId === role.id),
					),
				);
				// Call the /groups endpoint passing the policy id for each match policy
				policiesWithRole.map(policy =>
					this.globalApiCallsService
						.getGroupsFromPolicy(policy.id, environment.environmentConstants.APP_ENDPOINT_CORE)
						.subscribe(groups => {
							// Each group includes a member list which (user list)
							const users: Array<IUser> = groups.reduce((output, group) => {
								return [...output, ...group.members];
							}, []);

							// Remove duplicates
							const uniqueUsers = users.filter((user, index, self) => {
								return index === self.findIndex(temp => temp.id === user.id);
							});

							this.store.dispatch(new SetAllMechanics(uniqueUsers));
						}),
				);
			});
	}

	/**
	 * Repair error messages
	 * @returns String with all error messages without duplicates
	 */
	errorMessages() {
		return [...new Set(this.errors)].map(error => `ERROR: ${error}`).join("\n");
	}
}
