import { Injectable } from "@angular/core";
import { addUnitsToContent } from "src/utils/addUnitsToContent";
import { getRepairStatusDetail } from "src/utils/getRepairStatusDetail";
import { getRepairStatusTitle } from "src/utils/getRepairStatusTitle";
import { getResolutionType } from "src/utils/getResolutionType";
import { getSeverity } from "src/utils/getSeverity";
import { getSeverityStatusIcon } from "src/utils/getSeverityStatusIcon";
import { getSeverityStatusIconJpg } from "src/utils/getSeverityStatusIconJpg";
import { getZonarOtherUUID } from "src/utils/getZonarOtherUUID";
import { getZonarTagComponentUUID } from "src/utils/getZonarTagComponentUUID";
import { newDate } from "src/utils/newDate";
import { sortStaticInfo } from "src/utils/sortStaticInfo";
import { timeLapse } from "src/utils/timeLapse";
import { formatWord, translate, translateAndFormat } from "../i18next";
import { formatDate } from "../i18next/formatDate";
import {
	AddtlAssetInfoViewModel,
	InspectionAssetViewModel,
	InspectionDefectViewModel,
	InspectionDetailDatumViewModel,
	InspectionDetailFormDataPhotosViewModel,
	InspectionDetailListViewViewModel,
	InspectionDetailStaticInfoSortedField,
	InspectionDetailStaticInfoViewModel,
	InspectionDetailViewModel,
	InspectionZoneViewModel,
	RepairViewModel,
} from "../models/inspection-detail-view-models.models";
import {
	Asset,
	InspectionAsset,
	InspectionComponent,
	InspectionDefect,
	InspectionDetailData,
	InspectionGet,
	InspectionZone,
	RepairResponse,
} from "../models/openAPIAliases";
import { Utils } from "../utils";
import { base64EncodedSeverityIcons as severityIcons } from "./../constants/base-64-encoded-severity-icons";
import { BooleanTransformService } from "./boolean-transform.service";
import { LanguageDictionaryService } from "./language-dictionary/language-dictionary.service";
import { LocaleService } from "./locale/locale.service";
import { PhotoViewModelService } from "./photo-view-model.service";

export class InspectionRepairs {
	[key: string]: Array<RepairResponse>;
}
@Injectable({
	providedIn: "root",
})
export class InspectionDetailsViewModelService {
	constructor(
		private booleanTransformService: BooleanTransformService,
		private photoViewModelService: PhotoViewModelService,
		private languageDictionaryService: LanguageDictionaryService,
		private localeService: LocaleService,
	) {}

	getInspectionDetailViewModel(inspection: InspectionGet): InspectionDetailViewModel {
		const inspectionDetailViewModel: InspectionDetailViewModel = {
			inspectionDetailListViewViewModel: this.createInspectionDetailListViewViewModel(inspection),
			staticInfo: this.getStaticInfo(inspection),
		};

		return inspectionDetailViewModel;
	}

	createInspectionDetailListViewViewModel(inspection: InspectionGet): InspectionDetailListViewViewModel {
		// Removing inspectionDetailDataType === "photo" from inspectionDetailData records
		const inspectionDetailDataRecords =
			inspection.inspectionDetail.inspectionDetailData?.filter(
				inspectionDetail => inspectionDetail.inspectionDetailDataType !== "photo",
			) ?? inspection.inspectionDetail.inspectionDetailData;

		const inspectionDetailData: InspectionDetailDatumViewModel[] | null =
			this.getInspectionDetails(inspectionDetailDataRecords);

		const inspectionAssets: InspectionAssetViewModel[] = this.getInspectionAssets(inspection);

		const viewModel: InspectionDetailListViewViewModel = {
			inspectionDetailData: inspectionDetailData,
			inspectionAssets: inspectionAssets,
		};

		return viewModel;
	}

	getInspectionDetails(inspectionDetailData: InspectionDetailData[]): InspectionDetailDatumViewModel[] | null {
		const inspectionDetails: InspectionDetailDatumViewModel[] = [];

		if (inspectionDetailData) {
			const inspectionDetailsToPush = inspectionDetailData.map(
				(inspectionDetailDataItem: InspectionDetailData) => {
					const inspectionDetailDatum: InspectionDetailDatumViewModel = {
						languageLabel: formatWord(
							this.languageDictionaryService.getTranslations(
								inspectionDetailDataItem.inspectionDetailDataName,
							),
							"uppercase",
						),
						// To get the content transformed based on values recieved
						inspectionDetailContent:
							(inspectionDetailDataItem.inspectionDetailUnitConversionPair?.length ?? 0) > 0
								? inspectionDetailDataItem.inspectionDetailContent
								: this.booleanTransformService.booleanTransform(
										undefined,
										inspectionDetailDataItem.inspectionDetailContent,
								  ),
						inspectionDetailUnitConversionPair: inspectionDetailDataItem.inspectionDetailUnitConversionPair,
					};
					/* The conversion pair received is inconsitent i.e., not an array or undefined will send an empty array to normalize
					 * if the single value is received along with selected unit, abling to display the unit level
					 */
					let defaultSelectedUnit;
					if (
						!Array.isArray(inspectionDetailDatum.inspectionDetailUnitConversionPair) ||
						(Array.isArray(inspectionDetailDatum.inspectionDetailUnitConversionPair) &&
							inspectionDetailDatum.inspectionDetailUnitConversionPair.length === 0)
					) {
						defaultSelectedUnit = inspectionDetailDataItem?.inspectionDetailSelectedUnit;
						inspectionDetailDatum.inspectionDetailUnitConversionPair =
							defaultSelectedUnit === undefined
								? (inspectionDetailDatum.inspectionDetailUnitConversionPair = [])
								: defaultSelectedUnit;
					}
					inspectionDetailDatum.inspectionDetailContent = addUnitsToContent(
						inspectionDetailDataItem,
						inspectionDetailDatum,
						this.localeService,
					);

					return inspectionDetailDatum;
				},
			);

			inspectionDetails.push(...inspectionDetailsToPush);
		}

		return inspectionDetails;
	}

	getInspectionAssets(inspection: InspectionGet): InspectionAssetViewModel[] {
		const inspectionAssetViewModels: InspectionAssetViewModel[] = inspection.inspectionAssets.map(
			(inspectionAsset: InspectionAsset) => {
				const inspectionZones = !inspectionAsset.inspectionZones
					? null
					: inspectionAsset.inspectionZones.map((inspectionZone: InspectionZone) => {
							const time = inspectionZone.telemetry
								? formatDate(newDate(inspectionZone.telemetry.timestamp), "pp")
								: null;
							const day = inspectionZone.telemetry
								? formatDate(newDate(inspectionZone.telemetry.timestamp), "P")
								: null;

							const startTime = inspection.inspectionInfo.find(info => info.action === "start").telemetry
								.timestamp;
							const endTime = inspection.inspectionInfo.find(info => info.action === "end").telemetry
								.timestamp;

							const inspectionDefects = this.getViewModelDefects(
								inspectionZone.inspectionComponents,
								inspection.inspectionRepairs,
							);
							const defectAmount = inspectionDefects?.length ?? 0;

							const componentsWithoutDefects = inspectionZone.inspectionComponents
								.filter(
									({ inspectionDefects }) => !inspectionDefects || inspectionDefects?.length === 0,
								)
								.map(component =>
									this.languageDictionaryService.getTranslations(component.componentName),
								);

							const verificationType =
								inspectionZone.verificationType === "unverified"
									? this.getTagDefect(inspectionZone)
									: formatWord(
											inspectionZone.verificationType,
											// TODO: remove line above and uncomment lines bellow when translation is available
											// this.languageDictionaryService.getTranslations(
											//   inspectionZone.verificationType,
											//   inspection.configId
											// ),
											"capitalize",
									  );

							const zone: InspectionZoneViewModel = {
								day,
								languageLabel: this.languageDictionaryService.getTranslations(inspectionZone?.zoneName),
								inspectionDefects,
								inspectionEndTime: endTime,
								inspectionStartTime: startTime,
								severity:
									inspectionDefects && inspectionDefects?.length > 0
										? inspectionDefects.some(
												defect =>
													!defect?.repair ||
													(defect.repair &&
														defect.repair.statusTitle !==
															translateAndFormat("closed", "uppercase")),
										  )
											? defectAmount !== 0
												? inspectionDefects.some(
														defect => defect?.severity.toLowerCase() === "major",
												  )
													? "major"
													: "minor"
												: "none"
											: "none"
										: verificationType === "Uninspected"
										? "uninspected"
										: "none",
								time,
								verificationType,
								zoneLayoutId: inspectionAsset.zoneLayoutId.id,
								componentsWithoutDefects,
							};

							return zone;
					  });

				const asset: InspectionAssetViewModel = {
					languageLabel: this.languageDictionaryService.getTranslations(inspectionAsset.zoneLayoutName),
					assetId: inspectionAsset.asset ? inspectionAsset.asset.assetId : null,
					assetName: inspectionAsset.asset ? inspectionAsset.asset.assetName : null,
					assetCategory: inspectionAsset.asset ? inspectionAsset.asset.assetCategory : null,
					addtlAssetInfo: inspectionAsset.asset ? this.buildAddtlAssetInfo(inspectionAsset.asset) : null,
					hasDefects: inspectionZones?.some(zone => zone.inspectionDefects?.length > 0) ?? false,
					inspectionZones: inspectionZones,
					inspectionConfig: inspection.configId,
				};

				return asset;
			},
		);

		return inspectionAssetViewModels;
	}

	buildAddtlAssetInfo(asset: Asset): AddtlAssetInfoViewModel[] {
		const carrierDot: string = asset.assetCarrierDot ? asset.assetCarrierDot : "—";
		const assetDivision = asset.assetDivision;
		const location: string =
			assetDivision && asset.assetDivision.divisionName ? asset.assetDivision.divisionName : "—";
		const isRental = asset
			? asset.assetIsRental
				? translateAndFormat("yes", "capitalize")
				: translateAndFormat("no", "capitalize")
			: "—";

		const addtlAssetInfo: AddtlAssetInfoViewModel[] = [];
		if (Utils.isZonarTheme()) {
			addtlAssetInfo.push({
				languageLabel: translateAndFormat("dot", "uppercase"),
				addtlAssetContent: carrierDot,
			});
			if (asset.assetIsRental) {
				addtlAssetInfo.push({
					languageLabel: translateAndFormat("dot (rental)", "uppercase"),
					addtlAssetContent: asset.assetRentalDot,
				});
			}
		}
		addtlAssetInfo.push(
			{
				languageLabel: translateAndFormat("vin", "uppercase"),
				addtlAssetContent: asset?.assetVin ?? "—",
			},
			{
				languageLabel: translateAndFormat("power unit", "uppercase"),
				addtlAssetContent: asset?.assetPowerUnitNumber ?? "—",
			},
			{
				languageLabel: translateAndFormat("rental", "uppercase"),
				addtlAssetContent: isRental,
			},
			{
				languageLabel: translateAndFormat("asset location", "uppercase"),
				addtlAssetContent: location,
			},
		);

		return addtlAssetInfo;
	}

	getTagDefect(inspectionZone: InspectionZone): string {
		let unverifiedString = inspectionZone.verificationType;

		if (inspectionZone.inspectionComponents) {
			const found = inspectionZone.inspectionComponents.find(component => {
				return component.componentName.languageKey === getZonarTagComponentUUID();
			})?.inspectionDefects?.[0];

			if (found) {
				unverifiedString +=
					found.conditionName.languageKey === getZonarOtherUUID()
						? ` - ${found.otherCondition}` // TODO: Use language key once exists
						: ` - ${this.languageDictionaryService.getTranslations(found.conditionName)}`;
			}
		}

		return this.titlecase(unverifiedString);
	}

	getViewModelDefects(
		inspectionComponents: Array<InspectionComponent>,
		inspectionRepairs: InspectionRepairs,
	): InspectionDefectViewModel[] {
		const containsNoDefects = inspectionComponents.every(
			({ inspectionDefects }) =>
				inspectionDefects === undefined || inspectionDefects === null || inspectionDefects?.length === 0,
		);

		if (containsNoDefects) {
			return null;
		}

		return inspectionComponents.flatMap(({ inspectionDefects, componentName }) =>
			inspectionDefects?.reduce((defectView: InspectionDefectViewModel[], defect: InspectionDefect) => {
				const defectModel: InspectionDefectViewModel = {
					componentLabel: this.languageDictionaryService.getTranslations(componentName),
					conditionLabel: this.languageDictionaryService.getTranslations(defect.conditionName),
					defectComment: defect.defectComment ?? null,
					defectId: defect.defectId,
					photos: defect.defectMedia
						? this.photoViewModelService.buildPhotoViewModel(defect.defectMedia)
						: null,
					repair: this.getViewModelRepairUpdate(defect, inspectionRepairs),
					severity: getSeverity(defect.severity),
					severityIcon: getSeverityStatusIcon(defect.severity),
					severityIconJpg: getSeverityStatusIconJpg(defect.severity),
				};

				if (defect.otherCondition) {
					defectModel.otherCondition = defect.otherCondition;
				}

				if (defectModel.repair) {
					defectModel.repair.renderMap = [
						...defectModel.repair.renderMap,
						{
							label: translateAndFormat("photos", "uppercase"),
							value: defect.defectMedia ? "" : "—",
						},
					];
				}

				defectView.push(defectModel);

				return defectView;
			}, []),
		);
	}

	getViewModelRepairUpdate(defect: InspectionDefect, inspectionRepairs?: InspectionRepairs): RepairViewModel {
		if (!inspectionRepairs) {
			return null;
		}
		if (!inspectionRepairs[defect.defectId]) {
			return null;
		}
		if (inspectionRepairs[defect.defectId]?.length === 0) {
			return null;
		}

		const repairs: RepairResponse[] = inspectionRepairs[defect.defectId];
		const mostRecentRepairUpdate: RepairResponse = this.getMostRecentRepairStatus(repairs);

		// if the status is pending, show the same severity icon as the initial defect, else show green circle
		const severityIcon: string =
			mostRecentRepairUpdate.repairStatus === "pending"
				? getSeverityStatusIcon(defect.severity)
				: "assets/circle_check.svg";

		// if the status is pending, show the same severity icon as the initial defect, else show green circle
		const severityIconJpg: string =
			mostRecentRepairUpdate.repairStatus === "pending"
				? getSeverityStatusIconJpg(defect.severity)
				: severityIcons.circleCheckJpg;

		const mechanicFullName =
			defect.legacyDefectId && mostRecentRepairUpdate.repairStatus === "pending"
				? "—"
				: `${mostRecentRepairUpdate.mechanicLastName}, ${mostRecentRepairUpdate.mechanicFirstName}`;

		const repair: RepairViewModel = {
			comment: mostRecentRepairUpdate.comment,
			mechanicFirstName: mostRecentRepairUpdate.mechanicFirstName,
			mechanicId: mostRecentRepairUpdate.mechanicId,
			mechanicLastName: mostRecentRepairUpdate.mechanicLastName,
			mechanicFullName: mechanicFullName,
			renderMap: [
				{
					label: translateAndFormat("mechanic", "uppercase"),
					value: mechanicFullName,
				},
				{
					label: translateAndFormat("resolution", "uppercase"),
					value: getResolutionType(mostRecentRepairUpdate.repairStatus) ?? "—",
				},
				{ label: translateAndFormat("comments", "uppercase"), value: mostRecentRepairUpdate.comment ?? "—" },
			],
			resolution: mostRecentRepairUpdate.resolution,
			severityIcon: severityIcon,
			severityIconJpg: severityIconJpg,
			statusDetail: getRepairStatusDetail(mostRecentRepairUpdate.repairStatus),
			statusTitle: getRepairStatusTitle(mostRecentRepairUpdate.repairStatus),
		};

		return repair;
	}

	// todo: use a normal array sort override?
	// returns most recent repair comments, resolution, and mechanic
	public getMostRecentRepairStatus(repairStatus: RepairResponse[]): RepairResponse {
		// get all repair creation times as Dates in Dates array
		const dates = repairStatus.map((repair: RepairResponse) => newDate(repair.created));
		const mostRecentDate = dates.sort((dateA, dateB) => dateB.getTime() - dateA.getTime())[0];

		// send the repair array and most recent Date from Dates array
		return this.getMostRecentRepairObject(repairStatus, mostRecentDate);
	}

	// match the most recent Date to the the created Date in the repairs array and return most recent object
	public getMostRecentRepairObject(repairStatus: RepairResponse[], mostRecentDate: Date): RepairResponse {
		const mostRecentRepairObject = repairStatus.find((repair: RepairResponse) => {
			return newDate(repair.created).toISOString() === mostRecentDate.toISOString();
		});

		return mostRecentRepairObject;
	}

	public getFormDataPhotos(inspection: InspectionGet): Array<InspectionDetailFormDataPhotosViewModel> {
		let formDataPhotos: Array<InspectionDetailFormDataPhotosViewModel> = [];
		const inspectionDetailDataRecords = inspection.inspectionDetail.inspectionDetailData?.filter(
			inspectionDetail => inspectionDetail.inspectionDetailDataType === "photo",
		);

		inspectionDetailDataRecords.map((inspectionDetailData: InspectionDetailData) => {
			formDataPhotos = [
				...formDataPhotos,
				{
					images:
						inspectionDetailData.detailMedia?.length > 0
							? this.photoViewModelService.buildPhotoViewModel(inspectionDetailData.detailMedia)
							: undefined,
					defaultValue: "—",
					label: formatWord(
						this.languageDictionaryService.getTranslations(inspectionDetailData.inspectionDetailDataName),
						"uppercase",
					),
				},
			];
		});

		return formDataPhotos;
	}

	public getStaticInfo(inspection: InspectionGet): InspectionDetailStaticInfoViewModel {
		const thirdSignatureFirstName =
			inspection.inspectionReviews && inspection.inspectionReviews.length > 0
				? inspection.inspectionReviews[inspection.inspectionReviews.length - 1].inspectorFirstName
				: null;

		const thirdSignatureLastName =
			inspection.inspectionReviews && inspection.inspectionReviews.length > 0
				? inspection.inspectionReviews[inspection.inspectionReviews.length - 1].inspectorLastName
				: null;

		const thirdSignature =
			thirdSignatureFirstName && thirdSignatureLastName
				? `${thirdSignatureLastName}, ${thirdSignatureFirstName} ${translate(
						"has reviewed this EVIR and acknowledges the certification that all required repairs have been performed",
				  )}`
				: null;

		const startTime = inspection.inspectionInfo.find(info => info.action === "start").telemetry.timestamp;
		const endTime = inspection.inspectionInfo.find(info => info.action === "end").telemetry.timestamp;

		const start = newDate(startTime);
		const end = newDate(endTime);

		const staticInfo: InspectionDetailStaticInfoViewModel = {
			"3rd Signature": thirdSignature,
			"inspection type": this.languageDictionaryService.getTranslations(
				inspection.inspectionDetail.inspectionDetailName,
			),
			date: formatDate(start, "P"),
			time: formatDate(start, "pp"),
			duration: timeLapse(start, end),
			inspector: `${inspection.inspectorLastName} ${inspection.inspectorFirstName}`,
			jurisdiction: inspection.inspectionAssets[0].asset
				? inspection.inspectionAssets[0].asset.assetJurisdiction
				: null,
			license: inspection.inspectionAssets[0].asset ? inspection.inspectionAssets[0].asset.assetLicense : null,
			location: inspection.geolocation,
		};

		return staticInfo;
	}

	public getSortedStaticInfo(
		staticInfo: InspectionDetailStaticInfoViewModel,
	): InspectionDetailStaticInfoSortedField[] {
		return sortStaticInfo(staticInfo, [
			"date",
			"time",
			"inspector",
			"license",
			"jurisdiction",
			"location",
			"duration",
			"device",
			"3rd Signature",
		]);
	}

	// todo: move to a utils library
	titlecase(text: string): string {
		return text
			.trim()
			.split(" ")
			.map(word => word[0].toUpperCase() + word.substr(1).toLowerCase())
			.join(" ");
	}
}
