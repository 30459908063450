import { environment } from "src/environments/environment";

export const MockSidenavMenuConfiguration: Array<unknown> = [
	{
		text: "Defects (EVIR)",
		icon: "",
		appId: environment.environmentConstants.APP_APPLICATION_ID,
		route: "/defects",
		routerLink: "/defects",
		isExpanded: false,
		routeLevel: 1,
		children: [],
	},
	{
		text: "Inspection History (EVIR)",
		icon: "",
		appId: environment.environmentConstants.APP_APPLICATION_ID,
		route: "/inspection-list",
		routerLink: "/inspection-list",
		isExpanded: false,
		routeLevel: 1,
		children: [],
	},
	{
		text: "Missing Inspections (EVIR)",
		icon: "",
		appId: environment.environmentConstants.APP_APPLICATION_ID,
		route: "/missing-inspections",
		routerLink: "/missing-inspections",
		isExpanded: false,
		routeLevel: 1,
		children: [],
	},
	{
		text: "Settings (EVIR)",
		icon: "",
		appId: environment.environmentConstants.APP_APPLICATION_ID,
		route: "/settings",
		routerLink: "/settings",
		isExpanded: false,
		routeLevel: 1,
		children: [],
	},
];
