import { translateAndFormat } from "src/app/i18next";

export const getResolutionType = (repairStatus: string) => {
	switch (repairStatus) {
		case "pending":
			return translateAndFormat("pending", "capitalize");
		case "ignored":
			return translateAndFormat("repair not needed", "capitalize");
		case "repaired":
			return translateAndFormat("repaired", "capitalize");
		default:
			return "";
	}
};
