import { Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { Observable, ReplaySubject } from "rxjs";
import { map } from "rxjs/operators";
import { RequestLoadTable } from "src/app/app.state";

export const TableName = {
	assets: "getAssetsLoader",
	bigQueryAssets: "getBigQueryAssetsLoader",
	bigQueryDivisions: "getBigQueryDivisionsLoader",
	bigQueryInspectors: "getBigQueryInspectorsLoader",
	divisions: "getDivisionsLoader",
	inspectors: "getInspectorsLoader",
} as const;

export type TableName = keyof typeof TableName;

export interface TableRecordField {
	name: string;
	value: string;
}

interface Table {
	replaySubject: ReplaySubject<Array<TableRecord>>;
	fields: Array<FieldMeta>;
	latestValue: Array<TableRecord>;
	updated: number;
}
export interface TableRecord {
	fields: Array<TableRecordField>;
}
export interface FieldMeta {
	name: string;
}

export interface LoadTableRequest {
	tableName: TableName;
}

export interface UpdateContext {
	readonly tableName: string;
	readonly fields: ReadonlyArray<FieldMeta>;
	readonly records: TableRecord[];
}

function getReplaySubject(): ReplaySubject<Array<TableRecord>> {
	return new ReplaySubject<Array<TableRecord>>(1);
}
const key = "evir-db";
@Injectable({
	providedIn: "root",
})
export class LocalStoreService {
	tables: Record<string, Table> = {};

	constructor(private store: Store) {
		localStorage.removeItem(key);
	}

	startUpdate(tableName: string, fields: ReadonlyArray<FieldMeta>): UpdateContext {
		return {
			tableName: tableName,
			fields: fields,
			records: [],
		};
	}

	continueUpdate(context: UpdateContext, records: TableRecord[]): UpdateContext {
		return {
			...context,
			records: [...context.records, ...records],
		};
	}

	initializeTable(context: UpdateContext, updated: number): Table {
		// retain the initial subject, a query may be listening to it
		return {
			replaySubject: this.tables[context.tableName]
				? this.tables[context.tableName].replaySubject
				: getReplaySubject(),
			fields: [...context.fields],
			latestValue: [],
			updated: updated,
		};
	}

	completeUpdate(context: UpdateContext) {
		this.tables[context.tableName] = this.initializeTable(context, Date.now());
		this.publish(context.tableName, context.records);

		this.persist();
	}

	clear(tableName: string) {
		delete this.tables[tableName];
	}

	publish(tableName: string, records: Array<TableRecord>) {
		const publishedTable = this.tables[tableName];

		publishedTable.latestValue = [...records];
		publishedTable.replaySubject.next(records);
	}

	persist() {
		const tablesKeys = Object.keys(this.tables);

		const serialized = [
			...tablesKeys.map(tableName => {
				return {
					tableName: tableName,
					records: [...this.tables[tableName].latestValue],
					fields: [...this.tables[tableName].fields],
				};
			}),
		];
		localStorage.setItem(key, JSON.stringify(serialized));
	}

	restore() {
		const raw = localStorage.getItem(key);

		const deserialized = JSON.parse(raw);

		if (deserialized) {
			deserialized.forEach(entry => {
				const restoreTable = this.tables[entry.tableName];

				this.tables[entry.tableName] = {
					fields: [...entry.fields],
					replaySubject: restoreTable ? restoreTable.replaySubject : getReplaySubject(),
					latestValue: [],
					updated: 0,
				};

				this.publish(entry.tableName, entry.records);
			});
		}
	}

	query$(
		tableName: TableName,
		pattern: string,
		searchFieldNames: ReadonlyArray<string>,
	): Observable<Array<TableRecord>> {
		// construct a filter function that matches a record based on the searchable fields
		const filterFn = (record: TableRecord): boolean => {
			let matches = true;

			if (pattern) {
				matches =
					record.fields.filter(
						field =>
							searchFieldNames.includes(field.name) &&
							(field.value as string).toLocaleLowerCase().indexOf(pattern.toLocaleLowerCase()) >= 0,
					).length > 0;
			}

			return matches;
		};

		// if the table is not registed, then register it now
		if (!this.tables[tableName]) {
			this.tables[tableName] = this.initializeTable(
				{
					tableName: tableName,
					records: [],
					fields: [],
				},
				0,
			);
		}

		// if this table has not yet been successfully updated from the server, then request an update now
		const PersistenceLifetimeHours = 1;
		if (Date.now() - this.tables[tableName].updated > PersistenceLifetimeHours * (60 * 60 * 1000)) {
			this.tables[tableName] = { ...this.tables[tableName], updated: 0 };

			this.store.dispatch(
				new RequestLoadTable({
					tableName: tableName,
				}),
			);
		}

		// apply the filter function to the table records
		return this.tables[tableName].replaySubject.asObservable().pipe(map(records => records.filter(filterFn)));
	}
}
