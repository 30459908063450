<div class="ignored-repaired-card">
	<table
		mat-table
		matSort
		id="asset-closed-defect-table"
		[dataSource]="dataSource"
		matSortActive="severity"
		matSortDirection="desc"
		matSortDisableClear="true"
	>
		<ng-container
			matColumnDef="{{ column.column }}"
			*ngFor="let column of displayedColumnGroup"
		>
			<th
				mat-header-cell
				*matHeaderCellDef
				mat-sort-header
			>
				{{ column.displayName }}
			</th>
			<ng-container *ngIf="column.column === 'severity'">
				<td
					mat-cell
					*matCellDef="let element"
				>
					<div
						fxLayout="row"
						fxLayoutAlign="start center"
					>
						<img [src]="getSeverityIcon(element[column.column])" />
						<img
							class="arrow-icon"
							src="/assets/arrow.svg"
						/>
						<img src="/assets/severity-none.svg" />
					</div>
				</td>
			</ng-container>
			<ng-container *ngIf="column.column === 'inspection'">
				<td
					mat-cell
					*matCellDef="let element"
				>
					<a routerLink="/inspection/{{ element.inspectionId }}">
						{{ element[column.column] }}
					</a>
				</td>
			</ng-container>
			<ng-container *ngIf="column.column !== 'severity' && column.column !== 'inspection'">
				<td
					mat-cell
					*matCellDef="let element"
				>
					{{ element[column.column] }}
				</td>
			</ng-container>
		</ng-container>

		<tr
			mat-header-row
			*matHeaderRowDef="displayedColumns"
		></tr>
		<tr
			mat-row
			*matRowDef="let row; columns: displayedColumns"
		></tr>
	</table>

	<app-no-results-card
		*ngIf="noDefects"
		[message]="repairDefectString"
	></app-no-results-card>

	<div [hidden]="noDefects">
		<app-paginator
			[length]="totalClosedDefects"
			[pageIndex]="pageIndex"
			[pageSizeOptions]="pageSizeOptions"
			[pageSize]="pageSize"
			(pageIndexChange)="onPageIndexChange($event)"
			(pageQueryChange)="onQueryChange($event)"
			(pageSizeChange)="onPageSizeChange($event)"
		></app-paginator>
	</div>
</div>
