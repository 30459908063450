import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
	ViewEncapsulation,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Select, Store } from "@ngxs/store";
import { IUser } from "@zonar-ui/auth/lib/models/user.model";
import { Observable } from "rxjs";
import { filter } from "rxjs/operators";
import { AppState } from "src/app/app.state";
import { translateAndFormat } from "src/app/i18next";
import { formatDate } from "src/app/i18next/formatDate";
import { LanguageDictionaryService } from "src/app/services/language-dictionary/language-dictionary.service";
import { getZonarOtherUUID } from "src/utils/getZonarOtherUUID";
import { isDefined } from "src/utils/isDefined";
import { ClosedDefectViewModel, OpenDefectTableViewModel } from "../../../models/open-defect-table.models";
import { SeverityIconService } from "../../../services/severity-icon.service";
import { ClosedDefects } from "../asset-details.component";
import { AssetState, SetClosedWithComments } from "../state/asset-details.state";

export interface ColumnGroup {
	column: string;
	displayName: string;
}

@Component({
	selector: "app-closed-ignored",
	templateUrl: "./closed-ignored.component.html",
	styleUrls: ["./closed-ignored.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class ClosedIgnoredComponent implements OnInit, OnChanges {
	@Input() newClosedDefects: ClosedDefects;
	@Input() totalClosedDefects = 0;
	@Input() currentClosedDefectQuery = new URLSearchParams({
		page: "1",
		perPage: "5",
	});
	@Output() closedDefectPaginate = new EventEmitter<URLSearchParams>();

	@Select(AssetState.getClosedWithComments) closedWithComments$: Observable<ClosedDefectViewModel[]>;
	@Select(AppState.selectUser) user$: Observable<IUser>;

	public displayedColumns: string[] = [
		"severity",
		"repairedDate",
		"inspection",
		"zone",
		"component",
		"condition",
		"mechanic",
		"notes",
	];
	public displayedColumnGroup: ColumnGroup[] = [
		{
			column: "severity",
			displayName: translateAndFormat("severity", "uppercase"),
		},
		{
			column: "repairedDate",
			displayName: translateAndFormat("date", "uppercase"),
		},
		{
			column: "inspection",
			displayName: translateAndFormat("inspection", "uppercase"),
		},
		{ column: "zone", displayName: translateAndFormat("zone", "uppercase") },
		{
			column: "component",
			displayName: translateAndFormat("component", "uppercase"),
		},
		{
			column: "condition",
			displayName: translateAndFormat("condition", "uppercase"),
		},
		{
			column: "mechanic",
			displayName: translateAndFormat("mechanic", "uppercase"),
		},
		{
			column: "notes",
			displayName: translateAndFormat("notes / resolution", "uppercase"),
		},
	];
	public closedWithComments: ClosedDefectViewModel[] = [];
	public dataSource: MatTableDataSource<ClosedDefectViewModel>;
	public noDefects = false;
	public pageIndex = 0;
	public pageSize = 5;
	public pageSizeOptions = [5, 10, 20];
	public repairDefectString: string = translateAndFormat("please repair an open defect", "capitalize");
	public user: IUser;

	@ViewChild(MatSort, { static: true }) sort: MatSort;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

	constructor(
		private store: Store,
		private severityIconService: SeverityIconService,
		private languageDictionaryService: LanguageDictionaryService,
	) {}

	ngOnInit() {
		this.closedWithComments$.pipe(filter(isDefined)).subscribe((closedWithComments: ClosedDefectViewModel[]) => {
			this.dataSource = new MatTableDataSource(closedWithComments);
			this.closedWithComments = closedWithComments;

			if (closedWithComments.length === 0) {
				this.noDefects = true;
			}
		});

		this.user$.pipe(filter(isDefined)).subscribe((user: IUser) => {
			this.user = user;
		});

		if (this.dataSource) {
			this.dataSource.sort = this.sort;
			this.dataSource.paginator = this.paginator;
		}
	}

	// this will catch the input changes (closed defects) from parent
	// SimpleChanges is a hashmap of inputs (like newClosedDefects) that have a SimpleChange type that hold prev and cur vals
	// Only want to know if the entire object changed (new defects were closed) and use the new input val to update state
	ngOnChanges(changes: SimpleChanges) {
		if (changes.newClosedDefects && this.newClosedDefects !== null) {
			this.newClosedDefects.closedDefects.forEach((closedDefect: OpenDefectTableViewModel) => {
				const newClosedDefect: ClosedDefectViewModel = {
					component: closedDefect.componentLabel,
					condition: closedDefect.conditionLabel,
					inspection: closedDefect.inspectionType,
					inspectionId: closedDefect.inspectionId,
					mechanic: this.user ? `${this.user.lastName}, ${this.user.firstName}` : "—",
					notes: this.buildRepairNotes(this.newClosedDefects),
					repairedDate: formatDate(new Date()),
					severity: closedDefect.severity,
					zone: closedDefect.zoneLabel,
				};

				this.closedWithComments = [...this.closedWithComments, newClosedDefect];
			});

			this.store.dispatch(new SetClosedWithComments(this.closedWithComments));

			this.noDefects = false;
			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
		}
	}

	// if resolution (cleaned, adjusted, etc) anything but other, put resolution in notes column
	// if resolution is other, put the comment in notes instead
	// if no resolution, defect ignored, put "Repair not needed" in notes column
	public buildRepairNotes(defects: ClosedDefects): string {
		if (defects.resolution) {
			return defects.resolution ===
				this.languageDictionaryService.getTranslations({
					languageKey: getZonarOtherUUID(),
					languageLabel: "Other",
				})
				? defects.comment
				: defects.resolution;
		} else {
			return translateAndFormat("repair not needed", "capitalize");
		}
	}

	public getSeverityIcon(severity: number): string {
		return this.severityIconService.getSeverityIcon(severity);
	}

	updateTable(currentQueryStrings: URLSearchParams): void {
		this.closedDefectPaginate.emit(currentQueryStrings);
	}

	onPageIndexChange(pageIndex: number) {
		this.pageIndex = pageIndex;
	}

	onPageSizeChange(pageSize: number) {
		this.pageSize = pageSize;
	}

	onQueryChange(query: URLSearchParams): void {
		this.updateTable(new URLSearchParams(Object.fromEntries([...this.currentClosedDefectQuery, ...query])));
	}
}
