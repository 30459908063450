import { translateAndFormat } from "src/app/i18next";

export const getSeverity = (severity: number) => {
	switch (severity) {
		case 63:
			return translateAndFormat("minor", "capitalize");
		case 127:
			return translateAndFormat("minor", "capitalize");
		case 255:
			return translateAndFormat("major", "capitalize");
		default:
			return "";
	}
};
