import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { Select, Store } from "@ngxs/store";
import { PendoService } from "@zonar-ui/analytics";
import { PermissionsService } from "@zonar-ui/auth";
import { ICompany, IDivision } from "@zonar-ui/auth/lib/models/company.model";
import { IUserGroupPolicy } from "@zonar-ui/auth/lib/models/user-group-policy.model";
import { ChangeCompanyService } from "@zonar-ui/sidenav";
import { Observable, Subject, combineLatest, of } from "rxjs";
import { concatMap, distinctUntilChanged, filter, take, takeUntil } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { MockSidenavMenuConfiguration } from "src/mock-data/mock-sidenav-menu-configuration";
import { getCompanyContext } from "src/utils/getCompanyContext";
import { isDefined } from "src/utils/isDefined";
import { parseParamValuesFromURL } from "src/utils/parseParamValuesFromURL";
import { v4 as uuid } from "uuid";
import {
	AppState,
	GetApplicationObject,
	GetAssetTypes,
	GetAssets,
	GetConfig,
	GetDivisions,
	GetInspectionTypes,
	GetInspectors,
	GetLanguageDictionary,
	GetSettings,
	SetCompanyDivisions,
	SetCompanyHasTCU,
	SetSelectedCompany,
	SetUserDivisions,
	SetUserHasAllDivisions,
	SetUserObject,
} from "./app.state";
import { ErrorService } from "./components/errors/service/error.service";
import { SetNavState } from "./components/errors/state/error.state";
import { navErrorConstants } from "./constants/error-constants";
import { SetLogInfo } from "./log.state";
import { DataDogRumService } from "./services/data-dog-rum/data-dog-rum.service";
import { DbTableLoaderService } from "./services/db-table-loader/db-table-loader.service";
import { GlobalApiCallsService } from "./services/global-api-calls.service";
import { LoggerService } from "./services/logger.service";
import { NotificationService } from "./services/notification/notification.service";
import { PreviousPageService } from "./services/previous-page.service";

export interface RouteLink {
	label: string;
	link: string;
}

@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, OnDestroy {
	private activeAccountCode: string = null;
	private activeCompany: string;
	private currentCompany: string;
	private groupPolicyLoginMode = false;
	private onDestroy$ = new Subject<void>();
	private isLoading = true;

	@Select(AppState.getSelectedCompanyId) selectedCompanyId$: Observable<string>;
	@Select(AppState.selectUserDivisions) userDivisions$: Observable<Array<IDivision>>;
	@Select(AppState.selectUserHasAllDivisions) userHasAllDivisions$: Observable<boolean>;

	SIDENAV_PARAMS = {
		expanded: true,
		footer: true,
		footerOffset: false,
		hideChangeCompanyButton: false,
		hideLogoutButton: false,
		lockExpansion: false,
		mobileOpened: true,
		useDefaultHelpItem: true,
		enableContentResizing: true,
	};

	SIDENAV_MENU = MockSidenavMenuConfiguration;

	sidenavFooter: {
		items: [];
	};

	usingShadowEnvironment = environment.shadow;

	constructor(
		private changeCompanyService: ChangeCompanyService,
		private globalApiCallsService: GlobalApiCallsService,
		private pendoService: PendoService,
		private permissionsService: PermissionsService,
		public datadog: DataDogRumService,
		public dbTableLoaderService: DbTableLoaderService,
		public errorService: ErrorService,
		public logger: LoggerService,
		public notificationService: NotificationService,
		public previousPageService: PreviousPageService,
		public router: Router,
		public store: Store,
	) {}

	ngOnInit() {
		this.store.dispatch(new SetLogInfo({ sessionId: uuid() }));
		this.getActiveAccountCode();
		this.getCurrentUserLogin();
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
	}

	/**
	 * Dispatch the selected company to the store
	 * @param incomingCompany Current Company Context
	 */
	switchToSelectedCompany(incomingCompany: { title: string; value: string }) {
		if (!this.isLoading && incomingCompany?.value && incomingCompany.value !== this.currentCompany) {
			const companyId = incomingCompany.value;
			this.currentCompany = companyId;
			this.store.dispatch(new SetSelectedCompany(companyId));

			// TODO: Remove this when the (internal?) company context bug is fixed
			this.permissionsService.setCurrentCompanyContextById(companyId);
		}
	}

	/**
	 * Get the active account code from the URL query params if any
	 */
	private getActiveAccountCode() {
		this.router.events
			.pipe(
				takeUntil(this.onDestroy$),
				concatMap((event: any, index) => {
					if (event?.url && index === 0) {
						this.activeAccountCode = parseParamValuesFromURL(event.url, "active");
						if (this.activeAccountCode) {
							this.globalApiCallsService
								.getActiveGtcCompany(
									this.activeAccountCode,
									environment.environmentConstants.APP_ENDPOINT_CORE,
								)
								.pipe(
									filter<Array<ICompany>>(company => isDefined(company?.[0]?.id)),
									distinctUntilChanged(
										([{ id: previous }], [{ id: current }]) => previous === current,
									),
									take(1),
								)
								.subscribe(([{ id }]) => {
									if (id !== this.activeCompany) {
										this.activeCompany = id;
										this.changeCompanyService.changeSelectedCompany(id);
										this.permissionsService.setCurrentCompanyContextById(id); // TODO: Remove this when the (internal?) company context bug is fixed
										this.store.dispatch(new SetSelectedCompany(id));
										this.isLoading = false;
										this.initializeApp();
									}
								});
						} else {
							this.isLoading = false;
							this.initializeApp();
						}
					}
					return of(event);
				}),
			)
			.subscribe();
	}

	/**
	 * Get the current user object and update the store
	 */
	private getCurrentUserLogin() {
		this.permissionsService
			.getUser()
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(user => {
				if (user) {
					this.store.dispatch(new SetUserObject(user));
				}
			});
	}

	/**
	 * Initialize Pendo
	 */
	private initializePendo() {
		if (environment.analytics.pendo) {
			this.permissionsService
				.getIsFetchPermsSuccess()
				.pipe(
					takeUntil(this.onDestroy$),
					filter(permissions => Boolean(permissions)),
				)
				.subscribe(() => {
					this.pendoService.initialize();
				});
		}
	}

	/**
	 * Initialize the app
	 */
	private initializeApp() {
		this.setCurrentUserProfileOrPolicy();
		this.getEVIRData();
		this.initializePendo();
	}

	/**
	 * Set the current user profile or policy.
	 */
	private setCurrentUserProfileOrPolicy() {
		getCompanyContext(this.permissionsService.getCurrentCompanyContext()).subscribe(companyContext => {
			if (companyContext?.id && companyContext?.loginMode) {
				if (!this.activeCompany || this.activeCompany === companyContext.id) {
					this.groupPolicyLoginMode = companyContext.loginMode === "GROUP_POLICY";

					this.globalApiCallsService
						.getCompanyDivision(companyContext.id, environment.environmentConstants.APP_ENDPOINT_CORE)
						.pipe(take(1), filter(isDefined))
						.subscribe(companyDivisions => {
							this.store.dispatch(new SetCompanyDivisions(companyDivisions));
							if (this.groupPolicyLoginMode) {
								this.handleUserPolicies(companyContext.id, companyDivisions);
							} else {
								this.handleUserProfiles(companyContext.id, companyDivisions);
							}
						});
				}
			}
		});
	}

	/**
	 * Handle UserPolicies
	 * @param companyId Current company ID
	 * @param companyDivisions Array of all company divisions
	 */
	private handleUserPolicies(companyId: string, companyDivisions: Array<IDivision>) {
		// If the user has userPolicies
		this.permissionsService
			// Get the userGroupPolicies
			.getUserGroupPolicies()
			.pipe(takeUntil(this.onDestroy$), filter(isDefined))
			.subscribe(userGroupPolicies => {
				// Filter userGroupPolicies that belong to the EVIR application
				const userEvirGroupPolicies = userGroupPolicies.filter(groupPolicy =>
					groupPolicy.policy.grants.some(
						grant => grant.application.id === environment.environmentConstants.APP_APPLICATION_ID,
					),
				);

				// Process the received userGroupPolicies
				if (userEvirGroupPolicies?.length > 0) {
					const companyUserPolicies = userGroupPolicies.filter(groupPolicy =>
						groupPolicy.tenant.scope.companies.some(company => company.id === companyId),
					);
					const selectedUserGroupPolicy: IUserGroupPolicy = companyUserPolicies[0];

					const divisions =
						selectedUserGroupPolicy.tenant.scope.divisions?.length > 0
							? selectedUserGroupPolicy.tenant.scope.divisions
							: companyDivisions;

					this.store.dispatch(new SetUserDivisions(divisions));
					this.store.dispatch(new SetUserHasAllDivisions(divisions.length === companyDivisions.length));
				} else {
					this.store.dispatch(
						new SetNavState({
							http: false,
							type: navErrorConstants.NAV_ERROR_NO_ACCESS,
						}),
					);
					this.router.navigate(["/server-error"]);
				}
			});
	}

	/**
	 * Handle UserProfiles
	 * @param companyId Current company ID
	 * @param companyDivisions Array of all company divisions
	 */
	private handleUserProfiles(companyId: string, companyDivisions: Array<IDivision>) {
		// If the user has userProfiles
		this.permissionsService
			// Get the userProfiles
			.getUserProfiles()
			.pipe(takeUntil(this.onDestroy$), filter(isDefined))
			.subscribe(userProfiles => {
				// Filter userProfiles that belong to the EVIR application
				const userEvirProfiles = userProfiles.filter(
					profile => profile.applicationId === environment.environmentConstants.APP_APPLICATION_ID,
				);

				// Process the received userProfiles
				if (userEvirProfiles?.length > 0 && companyId) {
					const companyUserProfiles = userProfiles.filter(profile => profile.companyId === companyId);
					const selectedUserProfile =
						companyUserProfiles && companyUserProfiles.length ? companyUserProfiles[0] : userProfiles[0];

					const divisions =
						selectedUserProfile.divisions?.length > 0
							? companyDivisions.filter(division => selectedUserProfile.divisions.includes(division.id))
							: companyDivisions;

					this.store.dispatch(new SetUserDivisions(divisions));
					this.store.dispatch(new SetUserHasAllDivisions(divisions.length === companyDivisions.length));
				} else {
					this.store.dispatch(
						new SetNavState({
							http: false,
							type: navErrorConstants.NAV_ERROR_NO_ACCESS,
						}),
					);
					this.router.navigate(["/server-error"]);
				}
			});
	}

	/**
	 * Get the amount of TCU devices for the selected company
	 * @param companyId Selected Company ID
	 */
	private getCompanyTCUs(companyId: string) {
		this.globalApiCallsService
			.getCompanyTCUDevices(companyId, environment.environmentConstants.APP_ENDPOINT_CORE)
			.pipe(filter(isDefined))
			.subscribe(response => {
				const devices = parseInt(response.headers.get("x-total-count"), 10);
				this.store.dispatch(new SetCompanyHasTCU(devices > 0));
			});
	}

	/**
	 * Get EVIR Data such as configs, settings, translations and filter's data
	 */
	getEVIRData() {
		combineLatest([this.selectedCompanyId$, this.userHasAllDivisions$, this.userDivisions$])
			.pipe(
				takeUntil(this.onDestroy$),
				filter(
					([companyContext, userHasAllDivisions, userDivisions]) =>
						isDefined(companyContext) && isDefined(userHasAllDivisions) && isDefined(userDivisions),
				),
				distinctUntilChanged(
					(
						[previousCompanyContext, previousUserHasAllDivisions, previousUserDivisions],
						[currentCompanyContext, currentUserHasAllDivisions, currentUserDivisions],
					) =>
						previousCompanyContext === currentCompanyContext &&
						previousUserHasAllDivisions === currentUserHasAllDivisions &&
						previousUserDivisions.length === currentUserDivisions.length,
				),
			)
			.subscribe(([selectedCompanyId, userHasAllDivisions, userDivisions]) => {
				const divisions = userDivisions.map(({ id }) => id);
				this.store.dispatch(
					new GetLanguageDictionary(selectedCompanyId, environment.environmentConstants.APP_ENDPOINT_EVIR),
				);
				this.store.dispatch(
					new GetSettings(selectedCompanyId, environment.environmentConstants.APP_ENDPOINT_EVIR),
				);
				this.store.dispatch(
					new GetConfig(selectedCompanyId, environment.environmentConstants.APP_ENDPOINT_EVIR),
				);
				this.store.dispatch(
					new GetInspectionTypes(selectedCompanyId, environment.environmentConstants.APP_ENDPOINT_EVIR),
				);
				this.store.dispatch(
					new GetAssetTypes(selectedCompanyId, environment.environmentConstants.APP_ENDPOINT_EVIR),
				);
				this.store.dispatch(
					new GetAssets(
						selectedCompanyId,
						environment.environmentConstants.APP_ENDPOINT_EVIR,
						userHasAllDivisions,
						divisions,
					),
				);
				this.store.dispatch(
					new GetInspectors(
						selectedCompanyId,
						environment.environmentConstants.APP_ENDPOINT_EVIR,
						userHasAllDivisions,
						divisions,
					),
				);
				this.store.dispatch(
					new GetDivisions(
						selectedCompanyId,
						environment.environmentConstants.APP_ENDPOINT_CORE,
						userHasAllDivisions,
						divisions,
					),
				);
				this.store.dispatch(
					new GetApplicationObject(
						environment.environmentConstants.APP_APPLICATION_ID,
						environment.environmentConstants.APP_ENDPOINT_CORE,
					),
				);
				this.getCompanyTCUs(selectedCompanyId);
			});
	}
}
