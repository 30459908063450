import { FormControl, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import { endOfDay, startOfDay } from "date-fns";
import { newDate } from "src/utils/newDate";

export function dateRangeValidator(): ValidatorFn {
	const errors = {
		invalidFormat: "date format should be in mm/dd/yyyy",
		dateGreater: "date cannot be later than today",
		startGreater: "start date cannot succeed end date",
		endLess: "end date cannot precede start date",
	};

	const today = endOfDay(newDate());

	return (group: FormGroup): ValidationErrors | null => {
		const startDateControl = group.get("startDate");
		const endDateControl = group.get("endDate");
		const focusControl = group.get("focus");

		const startDate = startDateControl.value;
		const endDate = endDateControl.value;
		const focus = focusControl.value;

		const controls = {
			startDate: startDateControl,
			endDate: endDateControl,
		};

		// If no value in focus control, set invalidFormat error and clear any error in the other date control
		if (!controls[focus].value) {
			controls[focus].setErrors({ invalidFormat: errors.invalidFormat });
			if (focus === "startDate" && endDate && endDate <= today) endDateControl.setErrors(null);
			if (focus === "endDate" && startDate && startDate <= today) startDateControl.setErrors(null);
			if (endDateControl.hasError("matDatepickerMax"))
				endDateControl.setErrors({ dateGreater: errors.dateGreater });
			return;
		}

		// If the date in the control with focus is greater than today, set dateGreater error
		if (controls[focus].value > today) {
			controls[focus].setErrors({ dateGreater: errors.dateGreater });

			// If start date is valid and the error is on the end date, clear the start date error
			if (startDate && focus === "endDate" && endDate > today && startDate <= today)
				startDateControl.setErrors(null);
			return;
		}

		// If start date is after the end date, set startGreater error on start date control
		if (focus === "startDate" && startDate && startOfDay(startDate) > startOfDay(endDate)) {
			startDateControl.setErrors({ startGreater: errors.startGreater });

			// Clear any error on end date control if its value is valid
			if (endDate && endDate <= today) endDateControl.setErrors(null);
		} else if (focus === "endDate" && endDate && startOfDay(endDate) < startOfDay(startDate)) {
			// If end date is before the start date, set endLess error on end date control
			endDateControl.setErrors({ endLess: errors.endLess });

			// Clear any error on start date or end date control if its value is valid
			if (startDate > today && endDate <= today) endDateControl.setErrors(null);
			if (startDate && startDate <= today) startDateControl.setErrors(null);
		} else {
			// If both start and end dates are valid, clear errors on both controls
			if (endDate && startDate && startDate <= today && endDate >= startDate) startDateControl.setErrors(null);
			if (startDate && endDate && endDate <= today && endDate >= startDate) endDateControl.setErrors(null);
		}

		return null;
	};
}

export class DateRangeErrorStateMatcher implements ErrorStateMatcher {
	isErrorState(control: FormControl | null): boolean {
		return control.dirty && control.invalid;
	}
}
