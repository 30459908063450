import { Component, Input, OnInit } from "@angular/core";

export interface Fragment {
	text: string;
	regularHighlight: boolean;
}

@Component({
	selector: "app-highlighted-text",
	templateUrl: "./highlighted-text.component.html",
	styleUrls: ["./highlighted-text.component.scss"],
})
export class HighlightedTextComponent implements OnInit {
	@Input()
	pattern: string;

	@Input()
	text: string;

	@Input()
	selected: boolean;

	fragments: Array<Fragment> = [];

	public displayValueTokens: string[];

	public patternMatcher: RegExp;

	ngOnInit() {
		if (this.text && this.pattern) {
			this.buildFragmentList();
		} else {
			this.fragments.push({
				text: this.text,
				regularHighlight: true,
			});
		}
	}

	buildFragmentList() {
		if (this.pattern.length === 0) {
			this.fragments.push({
				text: this.text,
				regularHighlight: false,
			});
		} else {
			/*This RegExp flag is used to return the multiple matches if found,supports any char format*/
			this.patternMatcher = new RegExp("(" + this.pattern + ")", "ig");
			this.displayValueTokens = this.text.split(this.patternMatcher);

			this.displayValueTokens.forEach(valuetoken => {
				if (this.pattern.toLocaleLowerCase() === valuetoken.toLowerCase()) {
					this.fragments.push({
						text: valuetoken,
						regularHighlight: false,
					});
				} else {
					this.fragments.push({
						text: valuetoken,
						regularHighlight: true,
					});
				}
			});
		}
	}
}
