import { environment } from "src/environments/environment";

export const getZonarTagComponentUUID = () => {
	const NA = environment.brand.copyright === "Zonar Systems";
	switch (environment.environment) {
		case "development":
			return "15c814c0-08d3-4f9c-a478-1712e5fe2ead";
		case "qa":
			return NA ? "9fca7cc2-b456-4b57-874e-db7603b5e79f" : "0af4f4e2-079f-47ad-853a-6d5b24531b80";
		case "production":
			return NA ? "40aaba9f-87b7-469b-8142-58e267ae18a0" : "7fef07ff-8224-4045-9d1a-253178ca291c";
	}
};
