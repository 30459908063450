import { ICompany } from "@zonar-ui/auth/lib/models/company.model";
import { Observable } from "rxjs";
import { distinctUntilChanged, filter } from "rxjs/operators";

/**
 *  Get the current company context from the PermissionsService and filter the duplicate values
 * @param getCurrentCompanyContext getCurrentCompanyContext from PermissionsService
 * @returns An Observable of the current company context that contains the loginMode and id
 */
export const getCompanyContext = (getCurrentCompanyContext: Observable<ICompany>) =>
	getCurrentCompanyContext.pipe(
		filter<ICompany | undefined>(company => Object.keys(company ?? {}).length > 1),
		distinctUntilChanged<ICompany>(({ id: previous }, { id: current }) => previous === current),
	);
