export function resolveObjectPath(path: Array<string>, obj: unknown) {
	let value: unknown;

	if (path.length === 0) {
		value = obj;
	} else if (path.length === 1) {
		if (obj) {
			value = obj[path[0]];
		}
	} else {
		const root = path.shift();

		value = resolveObjectPath(path, obj ? obj[root] : undefined);
	}
	return value;
}
