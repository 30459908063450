import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatSelect, MatSelectChange } from "@angular/material/select";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { Select, Store } from "@ngxs/store";
import { PermissionsService } from "@zonar-ui/auth";
import { Observable, Subscription, combineLatest } from "rxjs";
import { filter, take } from "rxjs/operators";
import { AppState, GetAssetTypeImage, PatchSettings } from "src/app/app.state";
import { Option } from "src/app/components/multi-select-dropdown/multi-select-dropdown.component";
import { currentLocale, translateAndFormat } from "src/app/i18next";
import { AssetTypeSettingsPayload, RetentionTimeSettingsPayload } from "src/app/models/api-payloads.models";
import { environment } from "src/environments/environment";
import { isDefined } from "src/utils/isDefined";
import { ConfigGet, Efigs, Id, SettingsGet, ZoneLayouts } from "../../models/openAPIAliases";

@Component({
	selector: "app-settings",
	templateUrl: "./settings.component.html",
	styleUrls: ["./settings.component.scss"],
})
export class SettingsComponent implements OnInit, OnDestroy {
	assetTypeImage: SafeHtml;
	assetTypes: Array<Option> = [];
	companyId: Id;
	optionsMapValue = {
		[translateAndFormat("1 Year", "title")]: 365,
		[translateAndFormat("2 Years", "title")]: 730,
		[translateAndFormat("3 Years", "title")]: 1095,
		[translateAndFormat("4 Years", "title")]: 1460,
		[translateAndFormat("5 Years", "title")]: 1825,
		[translateAndFormat("indefinitely", "title")]: 1000000000, // 1 billion
	};
	optionsMapRender = {
		365: translateAndFormat("1 year", "title"),
		730: translateAndFormat("2 years", "title"),
		1095: translateAndFormat("3 years", "title"),
		1460: translateAndFormat("4 years", "title"),
		1825: translateAndFormat("5 years", "title"),
		1000000000: translateAndFormat("indefinitely", "title"), // 1 billion
	};
	options: string[] = [
		translateAndFormat("1 year", "title"),
		translateAndFormat("2 years", "title"),
		translateAndFormat("3 years", "title"),
		translateAndFormat("4 years", "title"),
		translateAndFormat("5 years", "title"),
		translateAndFormat("indefinitely", "title"),
	];
	selectedAssetType: string;
	selectedValue: string;
	settings: SettingsGet;
	showImageRetention: boolean;
	subscriptions = new Subscription();

	@Select(AppState.getSelectedCompanyId) selectedCompanyId$: Observable<string>;
	@Select(AppState.selectAssetTypeImage) assetTypeImage$: Observable<SafeHtml>;
	@Select(AppState.selectAssetTypes) assetTypes$: Observable<ZoneLayouts>;
	@Select(AppState.selectConfig) config$: Observable<ConfigGet>;
	@Select(AppState.selectSettings) settings$: Observable<SettingsGet>;

	@ViewChild("retentionTimeChangeSelect", { static: true }) retentionTimeChangeSelect: MatSelect;

	constructor(
		private store: Store,
		private sanitizer: DomSanitizer,
		private permissionsService: PermissionsService,
	) {}

	ngOnInit() {
		this.subscriptions.add(
			this.assetTypeImage$.pipe(filter(isDefined)).subscribe((assetTypeImage: string) => {
				this.assetTypeImage = this.sanitizer.bypassSecurityTrustHtml(assetTypeImage);
			}),
		);

		this.subscriptions.add(
			combineLatest([this.settings$, this.assetTypes$, this.config$, this.selectedCompanyId$])
				.pipe(
					filter(
						([settings, assetTypes, config, selectedCompanyId]) =>
							isDefined(settings) &&
							isDefined(assetTypes) &&
							isDefined(config) &&
							isDefined(selectedCompanyId),
					),
				)
				.subscribe(([settings, assetTypes, config, selectedCompanyId]) => {
					this.processStateUpdate(settings, assetTypes, config, selectedCompanyId);
				}),
		);

		this.permissionsService
			.getIsZonarUser()
			.pipe(filter(isDefined), take(1))
			.subscribe(isZonarUser => {
				if (isZonarUser) {
					this.showImageRetention = true;
				}
			});
	}

	processStateUpdate(settings: SettingsGet, assetTypes: ZoneLayouts, config: ConfigGet, companyId: Id) {
		this.companyId = companyId;
		this.settings = settings;
		const assetTypesArray: Array<Option> = [];

		// set the controls to whatever the current settings are
		this.selectedValue = this.optionsMapRender[settings.inspectionRetention];

		// Generate the asset types array
		for (const uuid in assetTypes) {
			const localizationObject: Efigs = assetTypes[uuid];
			const assetType: Option = {
				value: uuid,
				label: localizationObject[currentLocale()] || localizationObject["en-us"],
			};
			assetTypesArray.push(assetType);
		}
		this.assetTypes = assetTypesArray;

		// Set the controls to whatever the current settings are
		const zoneLayoutId = this.assetTypes.find(assetType => assetType.value === settings.defaultZoneLayoutId)?.value;

		this.selectedAssetType = zoneLayoutId;
		const assetViewId = config.zoneLayouts.find(layout => layout.zoneLayoutId.id === zoneLayoutId)?.assetViewId;

		if (assetViewId) {
			this.store.dispatch(
				new GetAssetTypeImage(companyId, assetViewId, environment.environmentConstants.APP_ENDPOINT_EVIR),
			);
		}
	}

	handleRetentionTimeChange(event: MatSelectChange): void {
		this.selectedValue = event.value;

		const payload: RetentionTimeSettingsPayload = {
			companyId: this.companyId,
			imageRetention: this.optionsMapValue[event.value],
			inspectionRetention: this.optionsMapValue[event.value],
		};

		this.store.dispatch(new PatchSettings(payload, environment.environmentConstants.APP_ENDPOINT_EVIR));
	}

	handleAssetTypeChange(event): void {
		const payload: AssetTypeSettingsPayload = {
			companyId: this.companyId,
			defaultZoneLayoutId: event.value,
		};

		this.store.dispatch(new PatchSettings(payload, environment.environmentConstants.APP_ENDPOINT_EVIR));
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}
}
