import { translate } from "src/app/i18next";

export const getRepairStatusDetail = (repairStatus: string) => {
	switch (repairStatus) {
		case "pending":
			return `(${translate("pending")})`;
		case "ignored":
			return `(${translate("not needed")})`;
		case "repaired":
			return `(${translate("repaired")})`;
		default:
			return "";
	}
};
