import { environment } from "src/environments/environment";

export const getZonarOtherUUID = () => {
	const NA = environment.brand.copyright === "Zonar Systems";
	switch (environment.environment) {
		case "development":
			return "2ac58125-3def-4f3e-b917-f05eab9f6225";
		case "qa":
			return NA ? "f0a59aa0-49ea-4cbd-b2a0-a92a0e6635de" : "388fa38e-c342-479c-97d1-e9e0bd11895f";
		case "production":
			return NA ? "c1504250-8cf8-4391-988b-da2ddeac5b70" : "13d12931-5a8b-45df-88a3-9560600a3bc6";
	}
};
