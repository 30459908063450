import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { tap } from "rxjs/operators";
import { InspectionDetailStaticInfoViewModel } from "src/app/models/inspection-detail-view-models.models";
import { Geolocation } from "../../../models/evir-api.models";
import { Id, InspectionGet } from "../../../models/openAPIAliases";
import { InspectionService } from "../service/inspection.service";
import { InspectionStateService } from "./inspection-state.service";

export interface InspectionDetailStateModel {
	staticInfo: InspectionDetailStaticInfoViewModel;
	address?: string;
	coordinates: string;
	inspection?: InspectionGet;
}

const inspectionStateDefaults: InspectionDetailStateModel = {
	staticInfo: null,
	address: null,
	coordinates: null,
	inspection: null,
};

export class GetInspection {
	static readonly type = "[Inspection] GetInspection";
	constructor(public payload: string, public companyId: Id, public endpoint: string) {}
}

export class ClearInspection {
	static readonly type = "[Inspection] ClearInspection";
}

export class GetGeolocation {
	static readonly type = "[Inspection] GetGeolocation";
	constructor(public payload: string[], public endpoint: string) {}
}
@Injectable()
@State<InspectionDetailStateModel>({
	name: "inspection",
	defaults: inspectionStateDefaults,
})
export class InspectionState {
	constructor(private inspectionService: InspectionService, private inspectionStateService: InspectionStateService) {}

	@Selector()
	static getStaticInfo(state: InspectionDetailStateModel) {
		return state.staticInfo;
	}

	@Selector()
	static getInspection(state: InspectionDetailStateModel) {
		return state.inspection;
	}

	@Selector()
	static getCoordinates(state: InspectionDetailStateModel) {
		return state.coordinates;
	}

	@Selector()
	static getAddress(state: InspectionDetailStateModel) {
		return state.address;
	}

	@Action(GetInspection)
	getInspection(
		{ patchState }: StateContext<InspectionDetailStateModel>,
		{ payload, companyId, endpoint }: GetInspection,
	) {
		return this.inspectionService.getInspection(payload, companyId, endpoint).pipe(
			tap((result: InspectionGet) => {
				const {
					staticInfo: staticInfo,
					coordinates,
					inspection,
				} = this.inspectionStateService.populateState(result);
				patchState({
					staticInfo: staticInfo,
					coordinates: coordinates,
					inspection: inspection,
				});
			}),
		);
	}

	@Action(ClearInspection)
	clearInspection({ setState }: StateContext<InspectionDetailStateModel>) {
		return setState(inspectionStateDefaults);
	}

	@Action(GetGeolocation)
	getGeolocation(
		{ patchState, getState }: StateContext<InspectionDetailStateModel>,
		{ payload, endpoint }: GetGeolocation,
	) {
		const state = getState();
		return this.inspectionService.getGeolocation(payload[0], payload[1], endpoint).pipe(
			tap(
				(result: Geolocation) => {
					patchState({
						address: result.data[0].full_address ? result.data[0].full_address : state.coordinates,
					});
				},
				() => {
					patchState({
						address: "",
					});
				},
			),
		);
	}
}
