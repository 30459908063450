import {
	AfterViewInit,
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	QueryList,
	ViewChild,
	ViewChildren,
	ViewEncapsulation,
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatCheckbox, MatCheckboxChange } from "@angular/material/checkbox";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { MatMenuTrigger } from "@angular/material/menu";
import { MatRadioChange } from "@angular/material/radio";
import { endOfDay, getDay, startOfDay, subDays } from "date-fns";
import { Subscription } from "rxjs";
import { currentLocale, translateAndFormat } from "src/app/i18next";
import { dateFnsPatterns } from "src/app/i18next/date-fns-locales-patterns";
import { CustomDateRangeFilterState, DateRange } from "src/app/models/date-range.models";
import { CustomDateRangeFilterChange } from "src/app/models/emitter-events.models";
import { MemCacheService } from "src/app/services/mem-cache/mem-cache.service";
import { getDateRangeFromDates } from "src/utils/getDateRangeFromDates";
import { newDate } from "src/utils/newDate";
import { timeDifference } from "src/utils/timeDifference";
import { CustomCalendarHeaderComponent } from "../custom-calendar-header/custom-calendar-header.component";
import { CUSTOM_FORMATS, DateRangeAdapter } from "./custom-date-range-adapter";
import { DateRangeErrorStateMatcher, dateRangeValidator } from "./custom-date-range-validator";
import { getRequestBody } from "./util/get-request-body";

export type WeekDays = "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday";

@Component({
	selector: "app-custom-date-range-filter",
	templateUrl: "./custom-date-range-filter.component.html",
	styleUrls: ["./custom-date-range-filter.component.scss"],
	providers: [
		{
			provide: DateAdapter,
			useClass: DateRangeAdapter,
			deps: [MAT_DATE_LOCALE],
		},
		{ provide: MAT_DATE_FORMATS, useValue: CUSTOM_FORMATS },
	],
	encapsulation: ViewEncapsulation.None,
})
export class CustomDateRangeFilterComponent implements OnInit, OnDestroy, AfterViewInit {
	@Input() context: string;
	@Input() initEndDate: Date = newDate();
	@Input() initStartDate: Date = startOfDay(subDays(newDate(), 7));

	@Output() radioButtonChange = new EventEmitter<string>();
	@Output() submitDateRangeFilter = new EventEmitter<CustomDateRangeFilterChange>();

	@ViewChild(MatMenuTrigger, { static: false }) matMenuTrigger: MatMenuTrigger;
	@ViewChildren(MatCheckbox) checkboxes: QueryList<MatCheckbox>;

	defaultState: CustomDateRangeFilterState = {
		currentFilter: DateRange.previous7,
		dateRangeEndDate: this.initEndDate,
		dateRangeStartDate: this.initStartDate,
		exceptionsDates: [],
		exceptionsDayOfWeek: [],
		filterButtonText: translateAndFormat("previous 7 days", "capitalize"),
		potentialDateInputEndDate: this.initEndDate,
		potentialDateInputStartDate: this.initStartDate,
		potentialFilterButtonText: translateAndFormat("previous 7 days", "capitalize"),
		selectedDateRange: DateRange.previous7,
	};

	buttonIconValue = "arrow_drop_down";
	currentFilter: DateRange = DateRange.previous7;
	customHeader = CustomCalendarHeaderComponent;
	dateFormatPattern = "MM/DD/YYYY";
	dateRange = DateRange;
	dateRangeEndDate: Date = newDate();
	dateRangeStartDate: Date = newDate();
	exceptionsDates: Array<Date> = [];
	exceptionsDayOfWeek: Array<number> = [];
	filterButtonClass = "filter-button-not-focused";
	filterButtonLabelClass = "filter-button-label-not-focused";
	filterButtonText: string = translateAndFormat("previous 7 days", "capitalize");
	potentialDateInputEndDate: Date = newDate();
	maxDateInputEndDate: Date = newDate();
	potentialDateInputStartDate: Date = newDate();
	potentialFilterButtonText: string = translateAndFormat("previous 7 days", "capitalize");
	state: CustomDateRangeFilterState;
	stateKey: string;
	subscriptions = new Subscription();
	weekDays: Record<WeekDays, number> = {
		sunday: 0,
		monday: 1,
		tuesday: 2,
		wednesday: 3,
		thursday: 4,
		friday: 5,
		saturday: 6,
	};
	errorMessage = "";

	dateRangeForm: FormGroup;
	matcher = new DateRangeErrorStateMatcher();

	constructor(private memCacheService: MemCacheService, private dateAdapter: DateAdapter<Date>) {
		this.dateAdapter.setLocale(currentLocale());
		CUSTOM_FORMATS.display.dateInput = dateFnsPatterns["P"][currentLocale()];
		CUSTOM_FORMATS.parse.dateInput = dateFnsPatterns["P"][currentLocale()];
	}

	ngOnInit() {
		this.stateKey = `custom-date-range-filter: ${this.context}`;

		this.dateFormatPattern = dateFnsPatterns["P"][currentLocale()];

		this.dateRangeForm = new FormGroup(
			{
				startDate: new FormControl(this.initStartDate),
				endDate: new FormControl(this.initEndDate),
				focus: new FormControl("startDate"),
			},
			{ validators: [dateRangeValidator()] },
		);

		// listen for clear event
		this.subscriptions.add(
			this.memCacheService.cleared$().subscribe(() => {
				this.memCacheService.setValue(this.stateKey, null);

				this.initState();

				this.filterSubmit();
			}),
		);

		// initialize from persisted state
		this.initState();
	}

	ngAfterViewInit() {
		this.updateCheckbox(this.dateRangeForm.controls.startDate.value, this.dateRangeForm.controls.endDate.value);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	/**
	 * Initialize the state from the persisted state
	 */
	initState() {
		this.state = this.getState();
		if (!this.state) {
			this.state = {
				...this.defaultState,
			};
		}

		this.currentFilter = this.state.currentFilter;
		this.dateRangeEndDate = this.state.dateRangeEndDate;
		this.dateRangeStartDate = this.state.dateRangeStartDate;
		this.exceptionsDates = this.state.exceptionsDates;
		this.exceptionsDayOfWeek = this.state.exceptionsDayOfWeek;
		this.filterButtonText = this.state.filterButtonText;
		this.potentialDateInputEndDate = this.state.potentialDateInputEndDate;
		this.potentialDateInputStartDate = this.state.potentialDateInputStartDate;
		this.potentialFilterButtonText = this.state.potentialFilterButtonText;

		this.state.selectedDateRange = this.state.currentFilter;
		this.dateRangeForm.controls.startDate.setValue(this.potentialDateInputStartDate, { emitEvent: false });
		this.dateRangeForm.controls.endDate.setValue(this.potentialDateInputEndDate, { emitEvent: false });

		const dateRange = getDateRangeFromDates({
			endDate:
				this.dateRangeStartDate.toDateString() !== this.dateRangeEndDate.toDateString()
					? this.dateRangeEndDate
					: undefined,
			startDate: this.dateRangeStartDate,
		}).map(getDay);

		this.checkboxes?.forEach((checkbox, index) => {
			const value = index === 6 ? 0 : index + 1;
			checkbox.checked =
				this.state.exceptionsDayOfWeek.length === 0
					? true
					: this.state.exceptionsDayOfWeek.every(exception => exception !== value);
			checkbox.disabled = dateRange.every(date => date !== value);
		});
	}

	/**
	 * Flush the state to the persisted state
	 */
	flushState() {
		this.memCacheService.setValue<CustomDateRangeFilterState>(this.stateKey, {
			...this.state,
			currentFilter: this.currentFilter,
			dateRangeEndDate: this.dateRangeEndDate,
			dateRangeStartDate: this.dateRangeStartDate,
			exceptionsDates: this.exceptionsDates,
			exceptionsDayOfWeek: this.exceptionsDayOfWeek,
			filterButtonText: this.filterButtonText,
			potentialDateInputEndDate: this.potentialDateInputEndDate,
			potentialDateInputStartDate: this.potentialDateInputStartDate,
			potentialFilterButtonText: this.potentialFilterButtonText,
		});
	}

	/**
	 * Handle custom dates being selected
	 */
	inlineRangeChange() {
		if (this.dateRangeForm.invalid) {
			this.currentFilter = DateRange.custom;
			this.potentialFilterButtonText = translateAndFormat("custom", "capitalize");
			this.state.selectedDateRange = DateRange.custom;

			if (!this.dateRangeForm.controls.startDate.value) {
				this.potentialDateInputEndDate = newDate();
				this.potentialDateInputStartDate = null;
			}
			if (!this.dateRangeForm.controls.endDate.value) {
				this.potentialDateInputStartDate = null;
				this.potentialDateInputEndDate = newDate();
			}

			this.updateCheckbox(
				this.dateRangeForm.controls.startDate.value,
				this.dateRangeForm.controls.endDate.value,
				true,
			);
			return;
		}

		this.potentialDateInputStartDate = this.dateRangeForm.controls.startDate.value;
		this.potentialDateInputEndDate = endOfDay(this.dateRangeForm.controls.endDate.value);

		// calculate if there is a default check option / update radio button
		const { days } = timeDifference(this.dateRangeForm.controls.startDate.value)(
			this.dateRangeForm.controls.endDate.value,
		);
		const defaultOptions = days === 0 || days === 7 || days === 14 || days === 30 || days === 365;

		if (defaultOptions && this.potentialDateInputEndDate.toDateString() === newDate().toDateString()) {
			//if default options selected
			switch (days) {
				case 0:
					this.rangeChange(DateRange.today, "today", startOfDay(newDate()));
					break;
				case 7:
					this.rangeChange(DateRange.previous7, "previous 7 days", startOfDay(subDays(newDate(), 7)));
					break;
				case 14:
					this.rangeChange(DateRange.previous14, "previous 14 days", startOfDay(subDays(newDate(), 14)));
					break;
				case 30:
					this.rangeChange(DateRange.previous30, "previous 30 days", startOfDay(subDays(newDate(), 30)));
					break;
				case 365:
					this.rangeChange(DateRange.previous365, "previous 365 days", startOfDay(subDays(newDate(), 365)));
					break;
			}
		} else {
			// if custom date selected
			this.currentFilter = DateRange.custom;
			this.potentialFilterButtonText = translateAndFormat("custom", "capitalize");
			this.state.selectedDateRange = DateRange.custom;
			this.updateCheckbox(this.potentialDateInputStartDate, this.potentialDateInputEndDate);
		}
	}

	/**
	 * Filter data on radio button change
	 * @param event Radio button change event
	 */
	matRadioChange(event: MatRadioChange) {
		switch (event.value) {
			case DateRange.today:
				this.radioChange(DateRange.today, "today", startOfDay(newDate()));
				break;

			case DateRange.previous7:
				this.radioChange(DateRange.previous7, "previous 7 days", startOfDay(subDays(newDate(), 7)));
				break;

			case DateRange.previous14:
				this.radioChange(DateRange.previous14, "previous 14 days", startOfDay(subDays(newDate(), 14)));
				break;

			case DateRange.previous30:
				this.radioChange(DateRange.previous30, "previous 30 days", startOfDay(subDays(newDate(), 30)));
				break;

			case DateRange.previous365:
				this.radioChange(DateRange.previous365, "previous 365 days", startOfDay(subDays(newDate(), 365)));
				break;

			case DateRange.custom:
				this.radioButtonChange.emit(DateRange.custom);
				this.currentFilter = DateRange.custom;
				this.potentialFilterButtonText = translateAndFormat("custom", "capitalize");
				this.updateCheckbox(
					this.dateRangeForm.controls.startDate.value,
					this.dateRangeForm.controls.endDate.value,
				);
				break;
		}

		this.state.selectedDateRange = this.currentFilter;
	}

	/**
	 * Stop event propagation on radio button click
	 * @param event Mouse event
	 */
	matRadioClick(event: MouseEvent) {
		event.stopPropagation();
	}

	/**
	 * Handle mat menu open
	 */
	handleMenuOpen() {
		this.errorMessage = "";
		this.buttonIconValue = "arrow_drop_up";
		this.filterButtonClass = "filter-button-focused";
		this.filterButtonLabelClass = "filter-button-label-focused";

		// reset the calendar
		this.potentialDateInputStartDate = this.dateRangeStartDate;
		this.potentialDateInputEndDate = this.dateRangeEndDate;

		// (re) initialize from persisted state
		this.initState();
	}

	/**
	 * Handle mat menu close
	 */
	handleMenuClose() {
		this.buttonIconValue = "arrow_drop_down";
		this.filterButtonClass = "filter-button-not-focused";
		this.filterButtonLabelClass = "filter-button-label-not-focused";
	}

	/**
	 * Submit filter changes
	 */
	filterSubmit() {
		// Handle default date range
		const startDate = this.dateRangeForm.controls.startDate.value;
		const endDate = this.dateRangeForm.controls.endDate.value;

		// Exceptions Day of Week
		this.exceptionsDayOfWeek = this.weekDayExceptionsArray();

		// Create Final Date Range event object
		const dateEvent = {
			dates: getRequestBody({
				endDate: endDate,
				excludedDates: this.exceptionsDates,
				excludedWeekDays: this.exceptionsDayOfWeek,
				startDate,
			}),
		};

		// Emit the event with the data
		this.submitDateRangeFilter.emit(dateEvent);
		this.matMenuTrigger.closeMenu();

		// Don't apply the change to the control until the user clicks 'apply'
		this.filterButtonText = this.potentialFilterButtonText;
		this.dateRangeStartDate = this.potentialDateInputStartDate;
		this.dateRangeEndDate = this.potentialDateInputEndDate;

		// Flush state
		this.flushState();
	}

	/**
	 * Cancel filter changes
	 */
	filterCancel() {
		this.matMenuTrigger.closeMenu();
	}

	matCheckboxChange(event: MatCheckboxChange) {
		const { days } = timeDifference(this.dateRangeForm.controls.startDate.value)(
			this.dateRangeForm.controls.endDate.value,
		);
		const numberOfExceptionalDays = this.weekDayExceptionsArray().length;

		if (numberOfExceptionalDays === 7 || numberOfExceptionalDays === days + 1) {
			this.checkboxes.find(checkbox => checkbox.id === event.source.id).checked = true;
			this.errorMessage = translateAndFormat("you need to keep at least one day checked", "capitalize");
		} else {
			this.errorMessage = "";
		}
	}

	/**
	 * Week day exceptions array
	 * @returns Array of numbers representing the week day exceptions
	 */
	private weekDayExceptionsArray() {
		let exceptions: Array<number> = [];
		this.checkboxes.forEach((checkbox: MatCheckbox, index) => {
			if (!checkbox.checked) {
				const value = index === 6 ? 0 : index + 1;
				exceptions = [...exceptions, value];
			}
		});

		return exceptions;
	}

	/**
	 * Get CustomDateRangeFilter State
	 * @returns state
	 */
	private getState() {
		return this.memCacheService.getValue<CustomDateRangeFilterState>(this.stateKey);
	}

	/**
	 * Update the internal state of the filter based on a date range change
	 * @param dateRange Enum with the date range selected
	 * @param filterButtonText Filter button text to display
	 * @param potentialStartDate Start date of the date range
	 */
	private rangeChange(dateRange: DateRange, filterButtonText: string, potentialStartDate: Date) {
		this.potentialDateInputStartDate = potentialStartDate;
		this.potentialDateInputEndDate = newDate();
		this.currentFilter = dateRange;
		this.potentialFilterButtonText = translateAndFormat(filterButtonText, "capitalize");
		this.dateRangeForm.controls.startDate.setValue(this.potentialDateInputStartDate, {
			onlySelf: true,
			emitEvent: false,
		});
		this.dateRangeForm.controls.endDate.setValue(this.potentialDateInputEndDate, {
			onlySelf: true,
			emitEvent: false,
		});
		this.state.selectedDateRange = dateRange;
		this.updateCheckbox(this.potentialDateInputStartDate, this.potentialDateInputEndDate);
	}

	/**
	 * Update the internal state of the filter based on a radio input change
	 * @param dateRange Enum with the date range selected
	 * @param filterButtonText Filter button text to display
	 * @param potentialStartDate Start date of the date range
	 */
	private radioChange(dateRange: DateRange, filterButtonText: string, potentialStartDate: Date) {
		this.potentialDateInputStartDate = potentialStartDate;
		this.potentialDateInputEndDate = newDate();
		this.radioButtonChange.emit(dateRange);
		this.currentFilter = dateRange;
		this.potentialFilterButtonText = translateAndFormat(filterButtonText, "capitalize");
		this.dateRangeForm.controls.startDate.setValue(this.potentialDateInputStartDate, { emitEvent: false });
		this.dateRangeForm.controls.endDate.setValue(this.potentialDateInputEndDate, { emitEvent: false });
		this.updateCheckbox(this.dateRangeForm.controls.startDate.value, this.dateRangeForm.controls.endDate.value);
	}

	/**
	 * Set the current state of the exception days of the week
	 * @param startDate Start date of the date range
	 * @param endDate End date of the date range
	 */
	private updateCheckbox(startDate: Date, endDate: Date, disabled: boolean = false) {
		const dateRange = disabled
			? null
			: getDateRangeFromDates({
					endDate: startDate.toDateString() !== endDate.toDateString() ? endDate : undefined,
					startDate,
			  }).map(getDay);

		this.errorMessage = "";
		this.checkboxes.forEach((checkbox, index) =>
			Object.assign(checkbox, {
				checked: true,
				disabled: disabled ? disabled : dateRange.every(date => date !== (index === 6 ? 0 : index + 1)),
			}),
		);
	}

	setControlFocus(controlName: string) {
		this.dateRangeForm.controls.focus.setValue(controlName, { onlySelf: true, emitEvent: false });
	}
}
