import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Select } from "@ngxs/store";
import { Observable } from "rxjs";
import { InspectionListPrintViewModel } from "src/app/models/inspection-print-view.models";
import { InspectionHistoryState } from "../inspection-history/state/inspection-history.state";

@Component({
	selector: "app-inspections-print-view",
	templateUrl: "./inspections-print-view.component.html",
	styleUrls: ["./inspections-print-view.component.scss"],
})
export class InspectionsPrintViewComponent implements OnInit {
	viewModels: InspectionListPrintViewModel[] = [];
	previousPageUrl = "/inspection-list";
	previousPageTitle = "Inspection List";

	@Select(InspectionHistoryState.getInspectionsPrintViewViewModels)
	inspectionsPrintViewViewModels$: Observable<InspectionListPrintViewModel[]>;

	constructor(private router: Router) {}

	ngOnInit() {
		this.inspectionsPrintViewViewModels$.subscribe(viewModels => {
			this.viewModels = viewModels;
		});
	}

	clickedOnBack() {
		this.router.navigate([this.previousPageUrl]);
	}
}
